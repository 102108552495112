import { HttpResponse } from '@angular/common/http';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { chatHistory } from 'src/app/dataModel/chatHistory.model';
import { chatList } from 'src/app/dataModel/chatList.model';
import { contactList } from 'src/app/dataModel/contactList.model';
import { ScrollToBottomDirective } from 'src/app/directive/scroll-to-bottom.directive';
import { ChatService } from 'src/app/services/chat.service';
import { CredentialService } from 'src/app/services/credential.service';
import { WebSocketService } from '../../../services/web-socket.service';
import { ChatMessageDto } from '../../../models/ChatMessageDto';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialogRef,MatDialog } from '@angular/material/dialog';
import { DialingComponent } from '../../dialing/dialing.component';


import { receivingcallComponent } from '../../receivingcall/receivingcall.component';
@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss']
})

export class GroupComponent implements OnInit {
  options: any;
  chatList: chatList[] = [];
  chatHistory: chatHistory[] = [];
  userName: string;
  userImage: string;
  loggedInUserId: string;
  loggedInUserName: string;
  loggedInUserImage: string;
  groupName:string;
  LoggedInUserPhone: string;
  isChatItemClicked: boolean = false;
  remoteUserId: string;
  messageValue: any;
  showEmojiPicker: boolean = false;
  contactList: contactList[] = [];
  nameSearch: any = '';
  grouparray: any =[];

  @ViewChild(ScrollToBottomDirective)
  scroll: ScrollToBottomDirective;

  @ViewChild('message') message: ElementRef;
  chatData: any;
  fileToUpload: File;
  public imgUrl: any;
  chatType: string;
  attachmentName: any;

  constructor(
    private router: Router,
    private _credential: CredentialService,
    private _chat: ChatService,
    private toastr: ToastrService,
    private WebSocketService: WebSocketService,
    public __sanitize: DomSanitizer,
    public dialog: MatDialog 

  ) { }

  ngOnInit(): void {
    this.getContactsList();
    this.loggedInUserId = this._credential.credentials._id;
    this.loggedInUserName = this._credential.credentials.firstName + ' ' + this._credential.credentials.lastName;
    this.loggedInUserImage = this._credential.credentials.profileImage;
    this.LoggedInUserPhone = this._credential.credentials.phone;
  }

  getContactsList() {
  ;
    var contactdata  = JSON.parse(localStorage.getItem(this._credential.credentials._id));
    if (contactdata&&contactdata.length>0)
    {
      this.contactList = JSON.parse(localStorage.getItem(this._credential.credentials._id));
      
    }
    else
    {
    this._chat.getContactList(
      this._credential.credentials._id,
      this._credential.credentials.chatToken,
      "WEB")
      .subscribe((response: HttpResponse<any>) => {
        if (response.status == 200 ||
          response.status == 201 ||
          response.status == 202 ||
          response.status == 204) {
          let users = response.body.response_data;

          let chatContactList = [];
          let counter=0;
          users.length && users.map(user => {
            if (user) {  
             
              if (user.firstName!=this._credential.credentials.firstName)
              {
                counter++;
                    
                chatContactList.push({
                  SNo:counter,
                  "RemoteUserFirstName": user.firstName,
                  RemoteUserOrGroupId: user._id,
                  RemoteUserLastName: user.lastName,
                  "chatType": "single",
                  msg: "",
                  RoomId: "",
                  RemoteUserOrGroupImage: "",
                  userId: user._id,
                  userName: user.userName,
                  phone: user.phone
                });
              }
           
            }

            this.contactList = chatContactList;
          });


          
        } else {
          this.toastr.error(response.body.response_message);
        }
      }, error => {
        this.toastr.error(error.error.message);
      });
  }
}
close()
{
  this.dialog.closeAll();
}

  addgroup(user) {
    this.grouparray.push(
      {
       id: user.RemoteUserOrGroupId
      }
    );
  }

  submitgroup()
  {
    if (this.groupName===undefined)
    {
      this.toastr.error("Please Enter Group Name.");
    }
    else{
      let groupstring='';
      this.grouparray.map(user => {
       groupstring= groupstring.length>0? groupstring+","+user.id : user.id;
      })
         let createGroup = {
           "image": "",
           "groupName": this.groupName,
           "groupUsers": groupstring,
           "token": this._credential.credentials.chatToken
         };
         this._chat.creategroup(createGroup)  .subscribe((response: HttpResponse<any>) => {
     
     this.toastr.success(" Group Created Successfully");
         }, error => {
           this.toastr.error(error.error.message);
         });;
     
       
         this.grouparray=[];

         this.dialog.closeAll();

         this.router.navigate(['/dashboard/chat']);
    }
 
  }
}
