<div class="container-fluid grad">
    <div class="row h-100 align-items-center">
        <div class="col-lg-6 offset-lg-3">
            <div class="row justify-content-center mt-5 mb-3">
                <img src="assets/img/logo.png" alt="" class="mt-5" />
            </div>
            <div class="w-100 my-5 py-5">
                <form class="p-5 mx-0 w-100 bg-white shadow-lg border-20">
                    <div class="row justify-content-center">
                        <h1 class="color-dd-gradient font-weight-bold">LOGIN</h1>
                    </div>
                    <div class="row justify-content-center">
                        <p class="text-muted-theme my-3 font-weight-bold">Enter your username and password to login below.</p>
                    </div>
                    <div class="input-group mb-3 shadow border-40 my-3">
                      <input type="email" class="form-control border-40 border-right-0 pl-5" placeholder="Enter User Name" aria-describedby="basic-addon2">
                      <div class="input-group-append">
                        <span class="input-group-text border-40 border-left-0 bg-white pr-4" id="basic-addon2">
                            <img height="53px" src="assets/img/user.png" alt="" />
                        </span>
                      </div>
                    </div>
                    <div class="input-group mb-3 shadow border-40 my-4">
                      <input type="password" class="form-control border-40 border-right-0 pl-5" placeholder="Enter Password" aria-describedby="basic-addon2">
                      <div class="input-group-append">
                        <span class="input-group-text border-40 border-left-0 bg-white pr-4" id="basic-addon2">
                            <img height="53px" src="assets/img/lock.png" alt="" />
                        </span>
                      </div>
                    </div>
                    <h5 class="text-center text-muted-theme my-5">Forgot your password?</h5>
                    <div class="row justify-content-center">
                        <a routerLink="./dashboard">
                        <button type="button" class="btn btn-theme border-40">Sign In</button>
                        </a>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
