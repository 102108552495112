
<div class="row my-3 mx-0 bg-white px-2 py-4">
    <div class="col-md-2 py-3"></div>
    <div class="col-md-2 py-3 d-flex justify-content-center">
        <button class="btn btn-call-log">All calls</button>
    </div>
    <div class="col-md-2 py-3 d-flex justify-content-center">
        <button class="btn btn-call-log">Missed call</button>
    </div>
    <div class="col-md-2 py-3 d-flex justify-content-center">
        <button class="btn btn-call-log">Received call</button>
    </div>
    <div class="col-md-2 py-3 d-flex justify-content-center">
        <button class="btn btn-call-log">Dialled call</button>
    </div>
    <div class="col-md-2 py-3"></div>
</div>

<div class="row my-3 mx-0 bg-white px-2 py-4">
    <div class="col-md-2 py-3">
        <div style="height: 60px; width: 60px; border-radius: 50%;">
            <img class="img-fluid" style="border-radius: 50%;"
            src="./assets/img/boy.png" alt="">
        </div>
    </div>
    <div class="col-md-6">
        <h4>Jennifer</h4>
        <p class="text-muted" style="font-family: sans-serif;">
            +1 2014545454
        </p>
    </div>
    <div class="col-md-4">
        <div class="audio-call-btn-wrapper">
            <button class="btn mx-2 rounded-circle btn-audio-call-action">
                <img height="20px" src="./assets/img/video-call.png" alt="">
            </button>
            <button class="btn mx-2 rounded-circle btn-audio-call-action">
                <img height="20px" src="./assets/img/phone.png" alt="">
            </button>
            <button class="btn mx-2 rounded-circle btn-audio-call-action">
                <img height="20px" src="./assets/img/chat-bubble.png" alt="">
            </button>
        </div>
    </div>
</div>

<div class="row my-3 mx-0 bg-white px-2 py-4">
    <div class="col-md-2 py-3">
        <div style="height: 60px; width: 60px; border-radius: 50%;">
            <img class="img-fluid" style="border-radius: 50%;"
            src="./assets/img/boy.png" alt="">
        </div>
    </div>
    <div class="col-md-6">
        <h4>Jennifer</h4>
        <p class="text-muted" style="font-family: sans-serif;">
            +1 2014545454
        </p>
    </div>
    <div class="col-md-4">
        <div class="audio-call-btn-wrapper">
            <button class="btn mx-2 rounded-circle btn-audio-call-action">
                <img height="20px" src="./assets/img/video-call.png" alt="">
            </button>
            <button class="btn mx-2 rounded-circle btn-audio-call-action">
                <img height="20px" src="./assets/img/phone.png" alt="">
            </button>
            <button class="btn mx-2 rounded-circle btn-audio-call-action">
                <img height="20px" src="./assets/img/chat-bubble.png" alt="">
            </button>
        </div>
    </div>
</div>
<div class="row my-3 mx-0 bg-white px-2 py-4">
    <div class="col-md-2 py-3">
        <div style="height: 60px; width: 60px; border-radius: 50%;">
            <img class="img-fluid" style="border-radius: 50%;"
            src="./assets/img/boy.png" alt="">
        </div>
    </div>
    <div class="col-md-6">
        <h4>Jennifer</h4>
        <p class="text-muted" style="font-family: sans-serif;">
            +1 2014545454
        </p>
    </div>
    <div class="col-md-4">
        <div class="audio-call-btn-wrapper">
            <button class="btn mx-2 rounded-circle btn-audio-call-action">
                <img height="20px" src="./assets/img/video-call.png" alt="">
            </button>
            <button class="btn mx-2 rounded-circle btn-audio-call-action">
                <img height="20px" src="./assets/img/phone.png" alt="">
            </button>
            <button class="btn mx-2 rounded-circle btn-audio-call-action">
                <img height="20px" src="./assets/img/chat-bubble.png" alt="">
            </button>
        </div>
    </div>
</div>
<div class="row my-3 mx-0 bg-white px-2 py-4">
    <div class="col-md-2 py-3">
        <div style="height: 60px; width: 60px; border-radius: 50%;">
            <img class="img-fluid" style="border-radius: 50%;"
            src="./assets/img/boy.png" alt="">
        </div>
    </div>
    <div class="col-md-6">
        <h4>Jennifer</h4>
        <p class="text-muted" style="font-family: sans-serif;">
            +1 2014545454
        </p>
    </div>
    <div class="col-md-4">
        <div class="audio-call-btn-wrapper">
            <button class="btn mx-2 rounded-circle btn-audio-call-action">
                <img height="20px" src="./assets/img/video-call.png" alt="">
            </button>
            <button class="btn mx-2 rounded-circle btn-audio-call-action">
                <img height="20px" src="./assets/img/phone.png" alt="">
            </button>
            <button class="btn mx-2 rounded-circle btn-audio-call-action">
                <img height="20px" src="./assets/img/chat-bubble.png" alt="">
            </button>
        </div>
    </div>
</div>
