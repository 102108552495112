
<header id="stickyHeader">
    <div class="container homepage">
      <div class="nav">
        <div class="logo">
          <a href="#">
            <img alt="logo" src="assets/img/logo-cbc.png" width="70">
          </a>
          <div class="line"></div>
          <ul>
            <li><a href="#about">about</a></li>
            <li><a href="#Services">Features</a></li>
            <li><a href="#team">team</a></li>
            <li><a href="#faq1">FAQ</a></li>
            <li class="pe-0"><a href="#/contact">Contact Us</a></li>
          </ul>
        </div>
        <div class="callto d-none">
          <a href="callto:+12344502086">+1234 450 2086</a>
          <i>
            <svg fill="none" height="112" viewBox="0 0 24 24" width="112" xmlns="http://www.w3.org/2000/svg">
              <g clip-rule="evenodd" fill="rgb(0,0,0)" fill-rule="evenodd">
                <path
                  d="m7 2.75c-.41421 0-.75.33579-.75.75v17c0 .4142.33579.75.75.75h10c.4142 0 .75-.3358.75-.75v-17c0-.41421-.3358-.75-.75-.75zm-2.25.75c0-1.24264 1.00736-2.25 2.25-2.25h10c1.2426 0 2.25 1.00736 2.25 2.25v17c0 1.2426-1.0074 2.25-2.25 2.25h-10c-1.24264 0-2.25-1.0074-2.25-2.25z" />
                <path
                  d="m10.25 5c0-.41421.3358-.75.75-.75h2c.4142 0 .75.33579.75.75s-.3358.75-.75.75h-2c-.4142 0-.75-.33579-.75-.75z" />
                <path
                  d="m9.25 19c0-.4142.33579-.75.75-.75h4c.4142 0 .75.3358.75.75s-.3358.75-.75.75h-4c-.41421 0-.75-.3358-.75-.75z" />
              </g>
            </svg>
          </i>
        </div>
      </div>
    </div>
  </header>
  <section class="hero-section">
<div class="container homepage">
    <div class="iq-get-in iq-pall-40 white-bg">
    <h4 class="text-center iq-tw-6 iq-pb-20 iq-mb-40 heading">{{dataTitle}}</h4>
    <div class="wrap">
       
        <div class="form_wrap shadow contactuspage">
            <form [formGroup]="myReactiveForm" (ngSubmit)="onSubmit()">
                <div class="row"  *ngIf="showMsg">
                    <div class="col-xs-12">
                        <p class="alert alert-success" *ngIf="resData!=''">{{resData}}</p>
                    </div>
                  </div>
            <div class="mb-5 section-field">
                <!-- <label for="username" class="form-label">Name</label> -->
                <input type="text" class="form-control" id="username" placeholder="name" formControlName="name">
                <small class="text-danger" *ngIf = "!myReactiveForm.get('name').valid && myReactiveForm.get('name').touched">Please enter Name</small>
                
            </div>
            <div class="mb-5 section-field">
                <!-- <label for="email" class="form-label">Email address</label> -->
                <input type="email" class="form-control" id="email" placeholder="name@example.com" formControlName="email">
                <small class="text-danger" *ngIf = "!myReactiveForm.get('email').valid && myReactiveForm.get('email').touched">Please enter valid email</small>
            </div>
            <div class="mb-5 section-field">
                <!-- <label for="username" class="form-label">Subject</label> -->
                <input type="text" class="form-control" id="subject" placeholder="subject" formControlName="subject">
                <small class="text-danger" *ngIf = "!myReactiveForm.get('subject').valid && myReactiveForm.get('subject').touched">Please enter subject</small>

            </div>
            
            <div class="mb-5 section-field">
                <!-- <label for="textarea" class="form-label">Massage</label> -->
                <textarea class="form-control" id="textarea" rows="3" formControlName="msg" placeholder="message"></textarea>
                <small class="text-danger" *ngIf = "!myReactiveForm.get('msg').valid && myReactiveForm.get('msg').touched">Please enter msg</small>

            </div>
            <!-- <div class="form-control">
                <ngx-recaptcha2
                #captchaElem
                siteKey="6LdYbF0lAAAAACUMZrZm1deTW_uPEMNck078AmI9"
                (success)="handleSuccess($event)"
                [size]="size"
                [hl]="lang"
                [theme]="theme"
                [type]="type"
                formControlName="recaptcha"
              >
              </ngx-recaptcha2>
                </div> -->
            <button type="submit"  class="batton button iq-mt-30 btn_submit pull-right">Submit</button>
            
            </form>
        </div>
    </div>
    </div>
</div>
</section>
<footer style="background-image: url(assets/img/footer.jpg);">
  <div class="container homepage">
    <div class="book-free">
      <img alt="paper-plan" src="assets/img/paper-plan.png">
      <h2>Book a Free Demo</h2>
      <p>Wondering how the CBC App works!. Let's connect</p>
      <a href="#contact" class="batton">Request a Demo</a>
      <ul>
        <li><a href="#"><i class="fa-brands fa-facebook-f"></i>facebook</a></li>
        <li><a href="#"><i class="fa-brands fa-instagram"></i>instagram</a></li>
        <li><a href="#"><i class="fa-brands fa-twitter"></i>twitter</a></li>
      </ul>
    </div>
    <p class="footer py-4" style="font-weight: 400;">2024 © CBC. Designed by <a href="#">All Rights Reserved</a></p>
  </div>
</footer>
<div class="container">
    <div class="row">
        <div class="col-md-12">
        
        </div>
    </div>
</div>
<style>
    .contactuspage .mb-5.section-field input {
	width: 100%;
	height: 64px;
	border: 0;
	border-radius: 12px;
	outline: 0;
	margin-bottom: 20px;
	padding-left: 30px;
	color: #444;
	font-size: 16px;
	padding-right: 86px;
	box-shadow: 0px 0px 20px 7px rgba(0, 0, 0, 0.08);
	-webkit-box-shadow: 0px 0px 20px 7px rgba(0, 0, 0, 0.08);
	-moz-box-shadow: 0px 0px 20px 7px rgba(0, 0, 0, 0.08);
	border: 1px solid #d3d3d3;
}
.batton.button.iq-mt-30.btn_submit.pull-right {
	padding: 26px 0;
	margin: 20px 0;
}
.iq-get-in.iq-pall-40.white-bg h4 {
	font-size: 48px !important;
	font-weight: bold;
	color: #fff !important;
	padding-bottom: 17px;
}
.logo a {
	margin: initial !important;
}
.container.homepage {
	max-width: 1320px !important ;
}

.hero-section {

	padding: 180px 0 !important;
}
footer {
	position: relative;
	text-align: center;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	padding-top: 3rem;
	 margin-top: 0; 
}
</style>