import { Injectable } from '@angular/core';

export interface Credentials {
  // Customize received credentials here
  chatToken : string;
  _id : string;
  authToken : string;
  answerTwo : string;
  questionTwo : string;
  answerOne : string;
  questionOne : string;
  otpVerify : string;
  email: string;
  phone: string;
  countryCode: string;
  profileImage: string;
  userName: string;
  lastName: string;
  firstName: string;
}
@Injectable({
  providedIn: 'root'
})
export class CredentialService {
  private _credentials: Credentials | null = null;
  constructor() {}

  get credentials(): Credentials | null{
    return this._credentials;
  }
  setCredentials(credentials?: Credentials) {
    this._credentials = credentials || null;
  }
}
