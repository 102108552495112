// script-loader.service.ts

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScriptLoaderService {

  loadScript(url: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const scriptElement = document.createElement('script');
      scriptElement.src = url;
      scriptElement.onload = () => resolve();
      scriptElement.onerror = () => reject(`Failed to load script ${url}`);
      document.head.appendChild(scriptElement);
    });
  }

}
