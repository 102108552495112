// export const environment = {
//   production: true,
//    apiUrl: 'https://call.cbcapps.com:4001/api',
//   apiChatUrl: 'https://chat.cbcapps.com:4002/api'

// };
export const environment = {
  production: true,
  //  apiUrl: 'http://192.168.0.168:4001/api',
  // apiChatUrl: 'http://192.168.0.168:4002/api'
  apiUrl: 'https://call.cbcapps.com:4001/api',
  apiChatUrl: 'https://chat.cbcapps.com:4002/api'


};