import { HttpResponse } from '@angular/common/http';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { chatHistory } from 'src/app/dataModel/chatHistory.model';
import { GroupUserdetails } from 'src/app/dataModel/GroupUserdetails.model';
import { chatList } from 'src/app/dataModel/chatList.model';
import { contactList } from 'src/app/dataModel/contactList.model';
import { ScrollToBottomDirective } from 'src/app/directive/scroll-to-bottom.directive';
import { ChatService } from 'src/app/services/chat.service';
import { CredentialService } from 'src/app/services/credential.service';
import { WebSocketService } from '../../../services/web-socket.service';
import { ChatMessageDto } from '../../../models/ChatMessageDto';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { DialingComponent } from '../../dialing/dialing.component';
import { receivingcallComponent } from '../../receivingcall/receivingcall.component';
import { GrpdetailsComponent } from '../../Updategroupdetails/grpdetails.component';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})

export class ChatComponent implements OnInit {
  options: any;
  chatList: chatList[] = [];
  chatHistory: chatHistory[] = [];
  userMobile: string;
  userName: string;
  userImage: string;
  isChatType:string;
  lastid: string = null;
  loggedInUserId: string;
  loggedInUserName: string;
  loggedInUserImage: string;
  LoggedInUserPhone: string;
  isChatItemClicked: boolean = false;
  sender_profile_img : string ='Unknown'
  remoteUserId: string;
  messageValue: any;
  showEmojiPicker: boolean = false;
  contactList: contactList[] = [];
  GroupUserdetails: GroupUserdetails[] = [];
  chatTypegroup: string;
  nameSearch: any = '';
  chatSection: string = "chatSection";
  @ViewChild(ScrollToBottomDirective)
  scroll: ScrollToBottomDirective;

  @ViewChild('message') message: ElementRef;
  chatData: any;
  fileToUpload: File;
  public imgUrl: any;
  chatType: string;
  attachmentName: any;

  constructor(
    private _credential: CredentialService,
    private _chat: ChatService,
    private toastr: ToastrService,
    private WebSocketService: WebSocketService,
    public __sanitize: DomSanitizer,
    private router: Router,
    public dialog: MatDialog,
    private _auth: AuthService,

  ) { }

  ngOnInit(): void {
    this.WebSocketService.connect();
    this.getChatList();
    this.listenerChat();
    const auth_data = JSON.parse(localStorage.getItem("auth_data"));
    this.sender_profile_img =auth_data.profileImage;
    this.loggedInUserId = this._credential.credentials._id;
    this.loggedInUserName = this._credential.credentials.firstName + ' ' + this._credential.credentials.lastName;
    this.loggedInUserImage = this._credential.credentials.profileImage;
    this.LoggedInUserPhone = this._credential.credentials.phone;

    this.WebSocketService.listen('chatReciveRemoteuser').subscribe((data: chatHistory) => {
      if (data.userId == this.remoteUserId || data.userid === this.remoteUserId) {
        this.chatHistory.push(data);
        setTimeout(() => {
          this.scroll.scrollToBottom();
        }, 300);
      }

    })
    this.WebSocketService.listen('SendChatCommit').subscribe((data: chatHistory) => {
      if (data.userId == this.remoteUserId) {
        this.chatHistory.push(data);
        setTimeout(() => {
          this.scroll.scrollToBottom();
        }, 300);
      }

    })



    this.WebSocketService.SendChatCommit("SendChatCommit").subscribe((data: any) => {
      //this.chatData = data;
      this.chatHistory.push(data);
      setTimeout(() => {
        this.scroll.scrollToBottom();
      }, 300);
    });




    this.WebSocketService.listen("groupleave").subscribe((data: any) => {
      this.getChatList();
    });


    this.WebSocketService.listen("groupedit").subscribe((data: any) => {
      this.chatHistory.push(data);
      window.location.reload();
    });

    this.WebSocketService.listen("groupadd").subscribe((data: any) => {
      this.chatHistory.push(data);
      setTimeout(() => {
        this.scroll.scrollToBottom();
      }, 300);
    });
    this.WebSocketService.listen("groupChatBroadCast").subscribe((data: any) => {
      this.chatHistory.push(data);
      setTimeout(() => {
        this.scroll.scrollToBottom();
      }, 300);
    });



    this.WebSocketService.listen('callRejected').subscribe((data: any) => {
      if (data.userId == this.remoteUserId) {
        this.chatHistory.push(data);
        setTimeout(() => {
          this.scroll.scrollToBottom();
        }, 300);
      }

    })

    this.WebSocketService.listen('userLoggedOut').subscribe((data: any) => {


      localStorage.clear();
      this.WebSocketService.disconnect();
      this._auth.userLogout()
        .subscribe((response: HttpResponse<any>) => {
          let res = JSON.parse(JSON.stringify(response));
          if (res.response_code == 2000) {
            this.toastr.success(res.response_message);
            localStorage.removeItem("auth_data");

            this.router.navigate(['']);
          } else {
            this.toastr.error(res.response_message);
            this.router.navigate(['']);
          }
        }, error => {
          console.log(error);
          this.toastr.error(error.error.message);
          this.router.navigate(['']);
        });
      this.router.navigate(['']);



    })
    this.WebSocketService.listen('onCallRecieved').subscribe((data: any) => {
      if (data.receiver_id == this.loggedInUserId) {
        localStorage.setItem("groupid", data.parent_id);
        localStorage.setItem('userid', this.loggedInUserId)
        localStorage.setItem('name', this.loggedInUserName)
        localStorage.setItem('callaccept', JSON.stringify(data));
        localStorage.setItem('rcvcall', data.sender_username);
        localStorage.setItem('sender_profile_img',data.sender_profile_img);
        this.dialog.open(receivingcallComponent, {
          'width': '900px',
          'height': '600px'
        });
        this.chatHistory.push(data);
        setTimeout(() => {
          this.scroll.scrollToBottom();
        }, 300);
      }

    })

    this.WebSocketService.listen('onCallRecievedUser').subscribe((data: chatHistory) => {
      localStorage.setItem('getdialerdata', JSON.stringify(data));
      if (data.userId == this.remoteUserId) {
        this.chatHistory.push(data);
        setTimeout(() => {
          this.scroll.scrollToBottom();
        }, 300);
      }
    })

  }
  filterItem(value) {
    if (!value) {
      this.getChatList();

    } // when nothing has typed
    else {

      this.chatList = this.chatList.filter(
        item => item.RemoteUserFirstName.toLowerCase().indexOf(value.toLowerCase()) > -1 || item.RemoteUserDisplayOrGroupName.toLowerCase().indexOf(value.toLowerCase()) > -1
      )
    }

  }
  getChatList() {
 
    this._chat.getChatList(
      this._credential.credentials._id,
      this._credential.credentials.chatToken)
      .subscribe((response: HttpResponse<any>) => {
        if (response.status == 200 ||
          response.status == 201 ||
          response.status == 202 ||
          response.status == 204) {

          this.chatList = response.body.response_data;

        } else {
          this.toastr.error(response.body.response_message);
          this.router.navigate(['']);
        }
      }, error => {
        this.toastr.error(error.error.message);
        this.router.navigate(['']);
      });
  }

  getContactsList() {

    var contactdata = JSON.parse(localStorage.getItem(this._credential.credentials._id));
    if (contactdata && contactdata.length > 0) {
      let users = JSON.parse(localStorage.getItem(this._credential.credentials._id));
      let groups = this.GroupUserdetails;
      let chatContactList = [];
      let counter = 0;
      let blockduplicate = false;
      users.length && users.map(user => {
        if (user) {
          if (user.RemoteUserFirstName != this._credential.credentials.firstName) {
            blockduplicate = false;
            groups.map(grpdata => {
              if (grpdata.cbc_id === user.userId) {
                blockduplicate = true;
              }
            })

            if (blockduplicate === false) {
              counter++;
              chatContactList.push({
                SNo: counter,
                "RemoteUserFirstName": user.RemoteUserFirstName,
                RemoteUserOrGroupId: user.RemoteUserOrGroupId,
                RemoteUserLastName: user.RemoteUserLastName,
                "chatType": "group",
                msg: "",
                RoomId: "",
                RemoteUserOrGroupImage: "",
                userId: user.userId,
                userName: user.userName,
                phone: user.phone
              });
            }
          }
        }
      });

      this.contactList = chatContactList;

    }
    else {
      this._chat.getContactList(
        this._credential.credentials._id,
        this._credential.credentials.chatToken,
        "WEB")
        .subscribe((response: HttpResponse<any>) => {
          if (response.status == 200 ||
            response.status == 201 ||
            response.status == 202 ||
            response.status == 204) {

            let users = response.body.response_data;
            let groups = this.GroupUserdetails;
            let chatContactList = [];
            let counter = 0;
            let blockduplicate = false;
            users.length && users.map(user => {
              if (user) {
                if (user.firstName != this._credential.credentials.firstName) {
                  blockduplicate = false;
                  groups.map(grpdata => {
                    if (grpdata.cbc_id === user._id) {
                      blockduplicate = true;
                    }
                  })

                  if (blockduplicate === false) {
                    counter++;

                    chatContactList.push({
                      SNo: counter,
                      "RemoteUserFirstName": user.firstName,
                      RemoteUserOrGroupId: user._id,
                      RemoteUserLastName: user.lastName,
                      "chatType": "group",
                      msg: "",
                      RoomId: "",
                      RemoteUserOrGroupImage: "",
                      userId: user._id,
                      userName: user.userName,
                      phone: user.phone
                    });
                  }
                }
              }
            });

            this.contactList = chatContactList;

          } else {
            this.toastr.error(response.body.response_message);
          }
        }, error => {
          this.toastr.error(error.error.message);
        });
    }
  }

  getChatHistory(remoteUserId, RemoteUserOrGroupImage, userImage) {
    this.chatSection = "";


    //peerconnection
    var peerConnectData = {
      "remoteuserid": remoteUserId,
      "userid": this._credential.credentials._id,
      "RoomId": ""
    }

    this.WebSocketService.emit("peerConnect", peerConnectData);


    //end peer connection
    this.remoteUserId = remoteUserId;
    this.userName = RemoteUserOrGroupImage;
    this.userImage = userImage;
    this._chat.getChatHistory(
      this._credential.credentials._id,
      this._credential.credentials.chatToken,
      remoteUserId)
      .subscribe((response: HttpResponse<any>) => {
        if (response.status == 200 ||
          response.status == 201 ||
          response.status == 202 ||
          response.status == 204) {
          this.isChatItemClicked = true;

          for (var i = 0; i < response.body.response_data.length; i++) {
            response.body.response_data[i].attachment = response.body.response_data[i].attachment.replace('http://chat.cbcapps.com:4002','https://chat.cbcapps.com:4002');
           


          }

          this.chatHistory = response.body.response_data;
          this.userMobile = response.body.phone;
        } else {
          this.toastr.error(response.body.response_message);
        }
      }, error => {
        this.toastr.error(error.error.message);
      });
  }

  getGroupChatHistory(remoteUserId, RemoteUserDisplayOrGroupName, userImage, chattype, RemoteUserFirstName) {
    this.isChatType = chattype; 
    localStorage.setItem("chatType",this.isChatType)

    if (this.lastid === null) { this.lastid = remoteUserId; }
    else {
      document.getElementById(this.lastid).style.backgroundColor = "#fff";
      this.lastid = remoteUserId;
    }
    document.getElementById(remoteUserId).style.backgroundColor = "#BBDEFB";
    this.GroupUserdetails = [];
    if (chattype === 'single') {
      this.chatTypegroup = chattype;
      this.contactList = [];
      this.getChatHistory(remoteUserId, RemoteUserDisplayOrGroupName, userImage)
    }
    else {
      this.chatSection = "";
      this.chatTypegroup = chattype;
      this.userImage = userImage;
      var grpchat = {
        "groupId": remoteUserId,
        "userId": this._credential.credentials._id,

      }
      this.WebSocketService.emit("groupConnect", grpchat);
      var peerConnectData = {
        "remoteuserid": remoteUserId,
        "userid": this._credential.credentials._id,
        "RoomId": ""
      }
      this.WebSocketService.emit("peerConnect", peerConnectData);
      //end peer connection
      this.remoteUserId = remoteUserId;
      this.userName = RemoteUserDisplayOrGroupName;
      this.userImage = userImage;
      this._chat.Getgrouphistory(
        this._credential.credentials.chatToken,
        remoteUserId
      )
        .subscribe((response) => {
          if (response.response_code == 2000
          ) {
            this.isChatItemClicked = true;

            for (var i = 0; i < response.response_data.length; i++) {
              let attachment = response.response_data[i].attachment.length > 0 ? response.response_data[i].attachment.replace('https://chat.cbcapps.com:4002:4002', 'https://chat.cbcapps.com:4002:4002') : "";
              response.response_data[i].attachment = attachment;
            }

            this.chatHistory = response.response_data;
          } else {
            this.toastr.error(response.body.response_message);
          }
        }, error => {
          this.toastr.error(error.error.message);
        });
      this.getGroupParticipantList();
    }

    this.userName = RemoteUserFirstName.length === 0 ? RemoteUserDisplayOrGroupName : RemoteUserFirstName;

  }

  addgroupparticipant(userid) {
    let createGroup = {
      "groupId": this.remoteUserId,
      "groupUsers": userid,
      "token": this._credential.credentials.chatToken
    };
    this._chat.Addingroup(createGroup).subscribe((response: HttpResponse<any>) => {

      this.toastr.success(" User added in Group  Successfully");
      this.getGroupParticipantList();
    }, error => {
      this.toastr.error(error.error.message);
    });


    this.WebSocketService.emit("addgroup", createGroup);
  }

  makeRandom() {
    let text = "";
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";
    const lengthOfCode = 9;
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text + "_" + new Date().getTime();
  }


  sendChat() {
    this.chatType = "text";
    var attachment = "";
    var chatType = "";
    var attachmentName = "";
    this.imgUrl = "";
    if (localStorage.getItem('fileData') == '0') {
      attachment = '';
      attachmentName = "";
      chatType = this.chatType;
    } else {
      // chatType = 'image';
      chatType = this.chatType;
      attachment = this.imgUrl;
      attachmentName = this.attachmentName;
    }
    if (this.message.nativeElement.value.length > 0) {
      if (this.chatTypegroup === 'group') {
        this.chatData = {
          "time": new Date().getTime(),
          "groupId": this.remoteUserId,
          "remoteuserimage": "",
          "jsonWebToken": this._credential.credentials.chatToken,
          "userId": this.loggedInUserId,
          "chat": this.message.nativeElement.value,
          "chatMode": "NONSECRET",
          "isForward": "no",
          "chat_id": this.makeRandom(),
          "type": "text",
          "chatText": this.message.nativeElement.value,
          "sender_username": this.loggedInUserName
        }

        this.WebSocketService.emit("groupChat", this.chatData);

        this.chatData = {
          "userid": this.loggedInUserId,
          "userId": this.loggedInUserId,
          "remoteuserid": this.remoteUserId,
          "time": new Date().getTime(),//timedtamp format in long
          "chat": this.message.nativeElement.value,
          "chatText": this.message.nativeElement.value,
          "jsonWebToken": this._credential.credentials.chatToken,
          "remoteuserimage": this.userImage,
          "type": chatType,//text, image, audio, video, doc/document
          "chatType": chatType,//text, image, audio, video, doc/document
          "chatMode": "NONSECRET",
          "attachment": attachment,//url from server
          "attachmentName": attachmentName,//local file name
          "size": "",
          "groupId": "",
          "isForward": false
        }
        this.chatHistory.push(this.chatData);


      }
      else {
        this.chatData = {
          "userid": this.loggedInUserId,
          "userId": this.loggedInUserId,
          "remoteuserid": this.remoteUserId,
          "time": new Date().getTime(),//timedtamp format in long
          "chat": this.message.nativeElement.value,
          "chatText": this.message.nativeElement.value,
          "jsonWebToken": this._credential.credentials.chatToken,
          "remoteuserimage": this.userImage,
          "type": chatType,//text, image, audio, video, doc/document
          "chatType": chatType,//text, image, audio, video, doc/document
          "chatMode": "NONSECRET",
          "attachment": attachment,//url from server
          "attachmentName": attachmentName,//local file name
          "size": "",
          "groupId": "",
          "isForward": false
        }
        this.WebSocketService.emit("sendchat", this.chatData);
        this.WebSocketService.SendChatCommit("SendChatCommit")

      }
    }


    ;
    //localStorage.setItem('fileData', '1');

    if (attachment != '') {
      localStorage.setItem('fileData', '0');
    }
    this.message.nativeElement.value = "";

  }


  getGroupParticipantList() {
    this._chat.getGroupParticipantList(

      this._credential.credentials.chatToken, this.remoteUserId
    )
      .subscribe((response) => {
        if (response.response_code == 2000) {

          this.GroupUserdetails = response.GroupUser;
          this.getContactsList();

        } else {
          this.toastr.error(response.response_message);
        }
      }, error => {
        this.toastr.error(error.error.message);
      });
  }

  listenerChat() {
    this.WebSocketService.listen("SendChatCommit");
  }

  leavegroup(userid) {
    let leavegroup = {
      "token": this._credential.credentials.chatToken,
      "groupId": this.remoteUserId,
      "userId": userid
    };
    this._chat.Leavegroup(
      leavegroup)
      .subscribe((response) => {

        this.toastr.success(response.body.response_message);
        this.getGroupParticipantList();
      })


    this.WebSocketService.emit("leavegroup", leavegroup);
  }


  Updateimage() {

    if (this.chatTypegroup == 'group') {
      this.dialog.open(GrpdetailsComponent, {
        'width': '600px',
        'height': '400px',
        data: { groupName: this.userName, groupid: this.remoteUserId, userImage: this.userImage }

      });
    }

  }

  handleFileInput(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('attachment', file);
      formData.append('attachmentName', 'CBC-' + Date.now() + '-' + file.type.split('/')[0]);
      formData.append('type', file.type.split('/')[0]);
      formData.append('userId', this._credential.credentials._id);
      formData.append('token', this._credential.credentials.chatToken);
      this._chat.fileUpload(formData).subscribe(
        (res) => {


          var checkFile = localStorage.setItem('fileData', '1');
          if (res) {
            this.imgUrl = res.response_data.attachment;
            this.chatType = "image";

            let extension = file['name'].substr(file['name'].lastIndexOf('.') + 1)
            if (extension == 'doc' || extension == 'txt' || extension == 'htm' || extension == 'docx' || extension == 'pdf' || extension === 'xlsx') {
              this.chatType = "doc";
            }
            if (extension == 'm4a' || extension == 'm3a') {
              this.chatType = "audio";
            }
            if (extension == 'mp3' || extension == 'mp4') {
              this.chatType = "video";
            }


            if (this.chatTypegroup === 'group') {
              let chatDatagrp = {


                "time": new Date().getTime(),
                "groupId": this.remoteUserId,
                "remoteuserimage": "",
                "jsonWebToken": this._credential.credentials.chatToken,
                "userId": this.loggedInUserId,
                "chat": this.message.nativeElement.value,
                "chatMode": "NONSECRET",
                "isForward": "no",
                "chat_id": this.makeRandom(),
                "type": this.chatType,
                "attachment": res.response_data.attachment,
                "attachmentName": res.response_data.attachmentName,
                "size": file.size

              }
              this.WebSocketService.emit("groupChat", chatDatagrp);
              this.getGroupChatHistory(this.remoteUserId, this.userName, this.userImage, this.chatTypegroup, "")

            }
            else {
              this.chatData = {
                "userid": this.loggedInUserId,
                "userId": this.loggedInUserId,
                "remoteuserid": this.remoteUserId,
                "time": new Date().getTime(),
                "jsonWebToken": this._credential.credentials.chatToken,
                "remoteuserimage": "",
                "type": this.chatType,//text, image, audio, video, doc/document
                "chat": "",//text, image, audio, video, doc/document
                "chatMode": "NONSECRET",
                "attachment": res.response_data.attachment,//url from server
                "attachmentName": res.response_data.attachmentName,//local file name
                "size": file.size
              }
              this.chatHistory.push(this.chatData);


              this.WebSocketService.emit("sendchat", this.chatData);
              this.WebSocketService.SendChatCommit("SendChatCommit");
              this.getChatHistory(this.remoteUserId, this.userName, this.userImage);

            }





            this.attachmentName = res.response_data.attachmentName;


          }

          //
        },
        (err) => console.log(err)
      );


    }
  }

  addEmoji(event) {
    this.message.nativeElement.value = this.message.nativeElement.value + event.emoji.native
    this.showEmojiPicker = false;
  }


  toggleEmojiPicker() {
    this.showEmojiPicker = !this.showEmojiPicker;
  }

  intializeVideoCall() {
    console.log('useruser', this.userName, this.remoteUserId)
    localStorage.setItem("dialname", this.userName);
    localStorage.setItem("profileImg", this.userImage);
    localStorage.setItem('jitsiInstialize', '0');
    let callData = {
      "call_type": "Video",
      "receiver_id": this.remoteUserId,
      "receiver_mobile_no": this.userMobile,
      "receiver_username": this.userName,
      "sender_userid": this.loggedInUserId,
      "sender_phone_no": this.LoggedInUserPhone,
      "sender_username": this.loggedInUserName,
      "user_type": "H",
      "status": "1",
      "userId": this.remoteUserId,
      "sender_phone_code": this._credential.credentials.countryCode,
      "sender_profile_img":this.sender_profile_img
    };
    this.dialog.open(DialingComponent, {
      'width': '900px',
      'height': '600px'
    });
    this.WebSocketService.emit('newCallConneted', callData);



  }


  intializeAudioCall() {
    console.log(this.userMobile)
    localStorage.setItem("dialname", this.userName);
    localStorage.setItem("profileImg", this.userImage);
    localStorage.setItem('jitsiInstialize', '0');
    let callData = {
      "call_type": "Audio",
      "receiver_id": this.remoteUserId,
      "receiver_mobile_no": this.userMobile,
      "receiver_username": this.userName,
      "sender_userid": this.loggedInUserId,
      "sender_phone_no": this.LoggedInUserPhone,
      "sender_username": this.loggedInUserName,
      "user_type": "H",
      "status": "1",
      "userId": this.remoteUserId,
      "sender_phone_code": this._credential.credentials.countryCode,
      "sender_profile_img":this.sender_profile_img
    };

    this.dialog.open(DialingComponent, {
      'width': '900px',
      'height': '600px'
    });
    this.WebSocketService.emit('newCallConneted', callData);
  }




}
