import { HttpResponse } from '@angular/common/http';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { chatHistory } from 'src/app/dataModel/chatHistory.model';
import { chatList } from 'src/app/dataModel/chatList.model';
import { contactList } from 'src/app/dataModel/contactList.model';
import { ScrollToBottomDirective } from 'src/app/directive/scroll-to-bottom.directive';
import { ChatService } from 'src/app/services/chat.service';
import { CredentialService } from 'src/app/services/credential.service';
import { WebSocketService } from '../../../services/web-socket.service';
import { ChatMessageDto } from '../../../models/ChatMessageDto';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { DialingComponent } from '../../dialing/dialing.component';
import { GroupComponent } from '../group/group.component';
import { Router } from '@angular/router';
import { receivingcallComponent } from '../../receivingcall/receivingcall.component';
import { debugOutputAstAsTypeScript } from '@angular/compiler';
import { LoaderService } from 'src/app/services/loader.service';
import { AuthService } from 'src/app/services/auth.service';
import { UtilsService } from 'src/app/services/utils.service';
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})

export class ContactComponent implements OnInit {
  options: any;
  chatList: chatList[] = [];
  chatHistory: chatHistory[] = [];
  userName: string;
  userImage: string;
  loggedInUserId: string;
  loggedInUserName: string;
  loggedInUserImage: string;
  LoggedInUserPhone: string;
  lastid: string = null;
  isChatItemClicked: boolean = false;
  remoteUserId: string;
  messageValue: any;
  sender_profile_img:string ='Unknown';
  showEmojiPicker: boolean = false;
  contactList: contactList[] = [];
  contactListMain: any = [];
  nameSearch: any = '';
  found_contact: any = [];
  items = [];
  chatSection: string = "chatSection";
  @ViewChild(ScrollToBottomDirective)
  scroll: ScrollToBottomDirective;

  @ViewChild('message') message: ElementRef;
  chatData: any;
  fileToUpload: File;
  public imgUrl: any;
  chatType: string;
  attachmentName: any;

  constructor(
    private _utils: UtilsService,
    private _auth: AuthService,
    private loaderService: LoaderService,
    private _credential: CredentialService,
    private _chat: ChatService,
    private toastr: ToastrService,
    private WebSocketService: WebSocketService,
    public __sanitize: DomSanitizer,
    public dialog: MatDialog,
    private router: Router


  ) {


  }

  ngOnInit(): void {
    this.WebSocketService.connect();
    this.items = Array(150).fill(0).map((x, i) => ({ id: (i + 1), name: `Item ${i + 1}` }));
    const auth_data = JSON.parse(localStorage.getItem("auth_data"));
    this.sender_profile_img =auth_data.profileImage;
    setTimeout(() => {
      var contactdata = JSON.parse(localStorage.getItem(this._credential.credentials._id));
      if (contactdata && contactdata.length >= 0) {
        this.loaderService.hide();
      }
      else {
        this.loaderService.show();
      }

    }, 2000);
    this.getContactsList();
    this.listenerChat();
    this.loggedInUserId = this._credential.credentials._id;
    this.loggedInUserName = this._credential.credentials.firstName + ' ' + this._credential.credentials.lastName;
    this.loggedInUserImage = this._credential.credentials.profileImage;
    this.LoggedInUserPhone = this._credential.credentials.phone;

   
   

    this.WebSocketService.listen('chatReciveRemoteuser').subscribe((data: chatHistory) => {

      if (data.userid === this.remoteUserId || data.userId === this.remoteUserId) {
        this.chatHistory.push(data);
        setTimeout(() => {
          this.scroll.scrollToBottom();
        }, 300);
      }

    })

    this.WebSocketService.listen('userLoggedOut').subscribe((data: any) => {


      localStorage.clear();
      this.WebSocketService.disconnect();
      this._auth.userLogout()
        .subscribe((response: HttpResponse<any>) => {
          let res = JSON.parse(JSON.stringify(response));
          if (res.response_code == 2000) {
            this.toastr.success(res.response_message);
            localStorage.removeItem("auth_data");

            this.router.navigate(['']);
          } else {
            this.toastr.error(res.response_message);
            this.router.navigate(['']);
          }
        }, error => {
          console.log(error);
          this.toastr.error(error.error.message);
          this.router.navigate(['']);
        });
      this.router.navigate(['']);



    })

    this.WebSocketService.SendChatCommit("SendChatCommit").subscribe((data: any) => {


      //this.chatData = data;
      this.chatHistory.push(data);
      setTimeout(() => {
        this.scroll.scrollToBottom();
      }, 300);
    });

    this.WebSocketService.listen('callRejected').subscribe((data: any) => {
      if (data.userId == this.remoteUserId) {
        this.chatHistory.push(data);

      }

    })


    this.WebSocketService.listen('onCallRecieved').subscribe((data: any) => {

      // debugger;
      if (data.receiver_id == this.loggedInUserId) {
        this.found_contact = this.contactList.filter(e => e.phone === data.sender_phone_no);
        localStorage.setItem("groupid", data.parent_id);
        localStorage.setItem('userid', this.loggedInUserId)
        localStorage.setItem('name', this.loggedInUserName)
        localStorage.setItem('callaccept', JSON.stringify(data));
        localStorage.setItem('rcvcall', this.found_contact[0]?.RemoteUserFirstName || data.sender_username);
        localStorage.setItem('sender_profile_img',data.sender_profile_img);
        this.dialog.open(receivingcallComponent, {
          'width': '900px',
          'height': '600px'
        });
        this.chatHistory.push(data);
      }
    })

    this.WebSocketService.listen('onCallRecievedUser').subscribe((data: chatHistory) => {
      localStorage.setItem('getdialerdata', JSON.stringify(data));
      if (data.userId == this.remoteUserId) {
        this.chatHistory.push(data);
        setTimeout(() => {
          this.scroll.scrollToBottom();
        }, 300);
      }
    })
  }


  filterItem(value) {
    if (!value) {
      this.getContactsList();

    } // when nothing has typed
    else {

      this.contactList = this.contactList.filter(
        item => item.RemoteUserFirstName.toLowerCase().indexOf(value.toLowerCase()) > -1
      )
    }

  }

  getChatList() {

    this._chat.getChatList(
      this._credential.credentials._id,
      this._credential.credentials.chatToken)
      .subscribe((response: HttpResponse<any>) => {
        if (response.status == 200 ||
          response.status == 201 ||
          response.status == 202 ||
          response.status == 204) {
          this.chatList = response.body.response_data;

        } else {
          this.toastr.error(response.body.response_message);
        }
      }, error => {
        this.toastr.error(error.error.message);
      });
  }

  getContactsListMain() {

    this._chat.getContactListMain(
      this._credential.credentials._id,
      this._credential.credentials.chatToken,
      "WEB")
      .subscribe((response: HttpResponse<any>) => {
        if (response.status == 200 ||
          response.status == 201 ||
          response.status == 202 ||
          response.status == 204) {
          this.loaderService.show();
          this.contactListMain = response.body.response_data;
          let chatContactList = [];
          let counter = 0;
          let userMain = this.contactListMain;
          userMain.length && userMain.map(user => {

            if (user) {

              if (user.display_name != this._credential.credentials.firstName) {
                //  if (user.is_sync==true)
                //  {
                if (chatContactList.length > 0 && chatContactList.find(x => x.phone === user.display_mobile_no) === undefined) {


                  // var details=userMain.find(x=>x.display_mobile_no.replace(' ','').replace('+','')===user.phone || x.display_mobile_no.replace(' ','').replace('+','')==(user.countryCode.replace('+','') +""+user.phone));
                  counter++;
                  chatContactList.push({
                    SNo: counter,
                    "RemoteUserFirstName": user.display_name,
                    RemoteUserOrGroupId: user.to_id,
                    RemoteUserLastName: "",
                    "chatType": "single",
                    msg: "",
                    RoomId: "",
                    RemoteUserOrGroupImage: "",
                    userId: user.to_id,
                    userName: user.display_name,
                    phone: user.display_mobile_no,
                    actiontype: false,
                    isblock: user.isblock,
                    Issync: user.is_sync,
                    profileImg: user.profileImg
                  });
                }
                else if (chatContactList.length === 0) {
                  counter++;
                  chatContactList.push({
                    SNo: counter,
                    "RemoteUserFirstName": user.display_name,
                    RemoteUserOrGroupId: user.to_id,
                    RemoteUserLastName: "",
                    "chatType": "single",
                    msg: "",
                    RoomId: "",
                    RemoteUserOrGroupImage: "",
                    userId: user.to_id,
                    userName: user.display_name,
                    phone: user.display_mobile_no,
                    actiontype: false,
                    isblock: user.isblock,
                    Issync: user.is_sync,
                    profileImg: user.profileImg
                  });
                }
                // }
              }
            }

          });


          var list_of_registered_contacts = [];
          var list_of_unregistered_contacts = [];

          chatContactList.map((single_contact_element) => {
            if (!single_contact_element.userId && !single_contact_element.RemoteUserOrGroupId) {
              list_of_unregistered_contacts.push(single_contact_element);
            }

            if (!!single_contact_element.userId && !!single_contact_element.RemoteUserOrGroupId) {
              list_of_registered_contacts.push(single_contact_element);
            }
          })
          list_of_registered_contacts = list_of_registered_contacts.sort(this._utils.dynamicSort("RemoteUserFirstName"));
          //list_of_unregistered_contacts = list_of_unregistered_contacts.sort(this._utils.dynamicSort("RemoteUserFirstName"));

          list_of_unregistered_contacts = list_of_unregistered_contacts.sort((a, b) => a.RemoteUserFirstName.localeCompare(b.RemoteUserFirstName));




          this.contactList = [...list_of_registered_contacts, ...list_of_unregistered_contacts];

          localStorage.setItem(this._credential.credentials._id, JSON.stringify(this.contactList));
          this.loaderService.hide();
        } else {
          this.toastr.error(response.body.response_message);
        }
      }, error => {
        this.toastr.error(error.error.message);
      });
  }

  getContactsList() {

    var contactdata = JSON.parse(localStorage.getItem(this._credential.credentials._id));
    if (contactdata && contactdata.length > 0) {
      this.loaderService.hide();
      this.contactList = JSON.parse(localStorage.getItem(this._credential.credentials._id));

    }
    else {
      this.loaderService.show();
      this.getContactsListMain();
    }
  }
  getcontactsublist() {
    this._chat.getContactList(
      this._credential.credentials._id,
      this._credential.credentials.chatToken,
      "WEB")
      .subscribe((response: HttpResponse<any>) => {
        if (response.status == 200 ||
          response.status == 201 ||
          response.status == 202 ||
          response.status == 204) {

          let users = response.body.response_data;
          let chatContactList = [];
          let counter = 0;
          let userMain = this.contactListMain;



          userMain.length && userMain.map(user => {
            if (userMain) {
              if (userMain.display_name != this._credential.credentials.firstName) {
                if (userMain.find(x => x.display_mobile_no.replace(' ', '').replace('+', '') === user.phone || x.display_mobile_no.replace(' ', '').replace('+', '') == (user.countryCode.replace('+', '') + "" + user.phone))) {
                  // var details=userMain.find(x=>x.display_mobile_no.replace(' ','').replace('+','')===user.phone || x.display_mobile_no.replace(' ','').replace('+','')==(user.countryCode.replace('+','') +""+user.phone));
                  counter++;
                  chatContactList.push({
                    SNo: counter,
                    "RemoteUserFirstName": userMain.display_name,
                    RemoteUserOrGroupId: userMain._id,
                    RemoteUserLastName: "",
                    "chatType": "single",
                    msg: "",
                    RoomId: "",
                    RemoteUserOrGroupImage: "",
                    userId: userMain._id,
                    userName: userMain.display_name,
                    phone: userMain.display_mobile_no,
                    actiontype: false,
                    profileImg: user.profileImg
                  });
                }
              }
            }

          });

          this.contactList = chatContactList;
          localStorage.setItem(this._credential.credentials._id, JSON.stringify(chatContactList));

          this.loaderService.hide();
        } else {
          this.toastr.error(response.body.response_message);
          this.router.navigate(['']);
        }
      }, error => {
        this.toastr.error(error.error.message);
        this.router.navigate(['']);
      });
  }
  changeclass(remoteUserId, RemoteUserOrGroupImage, userImage) {

    if (this.lastid === null) { this.lastid = remoteUserId; }
    else {
      document.getElementById(this.lastid).style.backgroundColor = "#fff";
      this.lastid = remoteUserId;
    }
    document.getElementById(remoteUserId).style.backgroundColor = "#BBDEFB";
  }
  getChatHistory(remoteUserId, RemoteUserOrGroupImage, userImage) {

    if (this.lastid === null) { this.lastid = remoteUserId; }
    else {
      document.getElementById(this.lastid).style.backgroundColor = "#fff";
      this.lastid = remoteUserId;
    }
    document.getElementById(remoteUserId).style.backgroundColor = "#BBDEFB";
    this.chatSection = "";
    var peerConnectData = {
      "remoteuserid": remoteUserId,
      "userid": this._credential.credentials._id,
      "RoomId": ""
    }
    this.WebSocketService.emit("peerConnect", peerConnectData);
    this.remoteUserId = remoteUserId;
    this.userName = RemoteUserOrGroupImage;
    this.userImage = userImage;
    this._chat.getChatHistory(
      this._credential.credentials._id,
      this._credential.credentials.chatToken,
      remoteUserId)
      .subscribe((response: HttpResponse<any>) => {
        if (response.status == 200 ||
          response.status == 201 ||
          response.status == 202 ||
          response.status == 204) {
          this.isChatItemClicked = true;

          for (var i = 0; i < response.body.response_data.length; i++) {
            response.body.response_data[i].attachment = response.body.response_data[i].attachment.replace('http://192.168.0.168:4002', 'http://192.168.0.168:4002');

          }

          this.chatHistory = response.body.response_data;
        } else {
          this.toastr.error(response.body.response_message);
        }
      }, error => {
        this.toastr.error(error.error.message);
      });
  }

  sendChat() {


    this.chatType = "text"
    var attachment = "";
    var chatType = "text";
    this.imgUrl = "";
    var attachmentName = "";
    if (localStorage.getItem('fileData') == '0') {
      attachment = '';
      attachmentName = "";
      chatType = this.chatType;
    } else {
      chatType = this.chatType == "undefined" ? "text" : this.chatType;
      attachment = this.imgUrl;
      attachmentName = this.attachmentName;
    }
    if (this.message.nativeElement.value.length > 0) {
      this.chatData = {
        "userid": this.loggedInUserId,
        "userId": this.loggedInUserId,
        "remoteuserid": this.remoteUserId,
        "time": new Date().getTime(),//timedtamp format in long
        "chat": this.message.nativeElement.value,
        "chatText": this.message.nativeElement.value,
        "jsonWebToken": this._credential.credentials.chatToken,
        "remoteuserimage": this.userImage,
        "type": chatType,//text, image, audio, video, doc/document
        "chatType": chatType,//text, image, audio, video, doc/document
        "chatMode": "NONSECRET",
        "attachment": attachment,//url from server
        "attachmentName": attachmentName,//local file name
        "size": "",
        "groupId": "",
        "isForward": false,

      }


      this.WebSocketService.emit("sendchat", this.chatData);

      this.WebSocketService.SendChatCommit("SendChatCommit");
    }

    //localStorage.setItem('fileData', '1');

    if (attachment != '') {
      localStorage.setItem('fileData', '0');
    }
    this.message.nativeElement.value = "";

  }
  listenerChat() {
    // this.WebSocketService.listen("SendChatCommit");
  }



  handleFileInput(event) {

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('attachment', file);
      formData.append('attachmentName', 'CBC-' + Date.now() + '-' + file.type.split('/')[0]);
      formData.append('type', file.type.split('/')[0]);
      formData.append('userId', this._credential.credentials._id);
      formData.append('token', this._credential.credentials.chatToken);
      this._chat.fileUpload(formData).subscribe(
        (res) => {

          if (res) {
            this.imgUrl = res.response_data.attachment;
            this.chatType = "image";

            let extension = file['name'].substr(file['name'].lastIndexOf('.') + 1)
            if (extension == 'doc' || extension == 'txt' || extension == 'htm' || extension == 'docx' || extension == 'pdf' || extension === 'xlsx') {
              this.chatType = "doc";
            }
            if (extension == 'm4a' || extension == 'm3a') {
              this.chatType = "audio";
            }
            if (extension == 'mp3' || extension == 'mp4') {
              this.chatType = "video";
            }

            this.chatData = {
              "userid": this.loggedInUserId,
              "userId": this.loggedInUserId,
              "remoteuserid": this.remoteUserId,
              "time": new Date().getTime(),
              "jsonWebToken": this._credential.credentials.chatToken,
              "remoteuserimage": "",
              "type": this.chatType,//text, image, audio, video, doc/document
              "chat": "",//text, image, audio, video, doc/document
              "chatMode": "NONSECRET",
              "attachment": res.response_data.attachment,//url from server
              "attachmentName": res.response_data.attachmentName,//local file name
              "size": file.size,
              "chatType": this.chatType
            }

            this.WebSocketService.emit("sendchat", this.chatData);
            this.WebSocketService.SendChatCommit("SendChatCommit");
            var checkFile = localStorage.setItem('fileData', '1');

            this.getChatHistory(this.remoteUserId, this.userName, this.userImage);
            this.attachmentName = res.response_data.attachmentName;

          }
        },
        (err) => console.log(err)
      );


    }
  }
  addEmoji(event) {
    this.message.nativeElement.value = this.message.nativeElement.value + event.emoji.native
    this.showEmojiPicker = false;
  }

  toggleEmojiPicker() {
    this.showEmojiPicker = !this.showEmojiPicker;
  }

  intializeVideoCall(user) {
    console.log("aaaaaaaa", user);

    localStorage.setItem("call_type", "Video");
    localStorage.setItem("dialname", user.userName);
    localStorage.setItem("profileImg", user.profileImg ? user.profileImg : '');

    localStorage.setItem('jitsiInstialize', '0');


    let callData = {
      "call_type": "Video",
      "receiver_id": user.userId,
      "receiver_mobile_no": user.phone,
      "receiver_username": user.userName,
      "sender_userid": this.loggedInUserId,
      "sender_phone_no": this.LoggedInUserPhone,
      "sender_username": this.loggedInUserName,
      "user_type": "H",
      "status": "1",
      "userId": user.userId,
      "sender_phone_code": this._credential.credentials.countryCode,
      "sender_profile_img":this.sender_profile_img
    };

    this.dialog.open(DialingComponent, {
      'width': '900px',
      'height': '600px'
    });

    this.WebSocketService.emit('newCallConneted', callData);



  } 


  intializeAudioCall(user) {

    if (user.isblock === true) {
      this.toastr.error("User is Blocked");
    }
    else {

      localStorage.setItem("call_type", "Audio");
      localStorage.setItem("dialname", user.RemoteUserFirstName);
      localStorage.setItem('profileImg', user.profileImg ? user.profileImg :'');
      localStorage.setItem('jitsiInstialize', '0');
      let callData = {
        "call_type": "Audio",
        "receiver_id": user.userId,
        "receiver_mobile_no": user.phone,
        "receiver_username": user.RemoteUserFirstName,
        "sender_userid": this.loggedInUserId,
        "sender_phone_no": this.LoggedInUserPhone,
        "sender_username": this.loggedInUserName,
        "user_type": "H",
        "status": "1",
        "userId": user.userId,
        "sender_phone_code": this._credential.credentials.countryCode,
        "sender_profile_img":this.sender_profile_img
      };
      this.WebSocketService.emit('newCallConneted', callData);
      this.dialog.open(DialingComponent, {
        'width': '900px',
        'height': '600px'
      });

    }
  }

  addongroup() {
    this.dialog.open(GroupComponent, {
      'width': '700px',
      'height': '550px'
    });
  }


}
