<h4 class="py-5 px-3 text-center text-theme">
    <img class="pr-3" 
        src="./assets/img/phone-call.png"
        height="25px"
        style="filter: invert(1);" alt="">
    CBC call
</h4>
<hr>
<h4 class="py-5 px-3 text-center text-theme">
    <img class="pr-3" 
        src="./assets/img/phone-call.png"
        height="25px"
        style="filter: invert(1);" alt="">
    Phone call
</h4>
