import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { Observable, Subscriber,Subscription } from 'rxjs';
//import {io,Socket} from 'socket.io-client';
import { ChatMessageDto } from '../models/ChatMessageDto';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {

  //socket: Socket;
  webSocket : WebSocket;
  chatMessaqges : ChatMessageDto[] = [];
  //readonly uri:string = 'https://nodeserver.mydevfactory.com:3011/';
  // readonly uri:string = 'http://192.168.0.165:7011/'//'http://203.129.220.85:7011/';
   readonly uri:string = 'https://call.cbcapps.com:4001/'
  //  readonly uri:string = 'https://192.168.0.168:4001/'

  constructor(  private router: Router,public socket:Socket) { 
    
    var auth_data = JSON.parse(localStorage.getItem("auth_data"));

    //this.socket = io(this.uri,{query: "chatToken="+auth_data.chatToken+"&"+"userid="+auth_data._id,autoConnect:false,reconnection:false});
    //socket.connect();
    //

    this.socket.on("chatReciveRemoteuser", (data) => {
      
    });

    /*this.socket.on("SendChatCommit", (data) => {
      
    });*/


    this.listen("userapearonline").subscribe(
      function(data){
        
      }
    );
  }
  connected = false;
  connect(){
    
    
    if(!this.connected){
      var auth_data = JSON.parse(localStorage.getItem("auth_data"));
      this.socket.ioSocket.io.opts.query = {chatToken: auth_data.chatToken,userId:auth_data._id,token:auth_data.authToken};
      this.socket.connect();
      
      this.connected = true;

      
    }
   
    
  }
  disconnect(){
   
    if(this.connected){
      this.connected=false;
      this.socket.disconnect();
      
    }
   
    
  }
  listen(eventName: string) {
    
   
    return new Observable((subscriber) => {
      this.socket.on(eventName, (data) => {
        
        
        subscriber.next(data);
      })
    });
  }

  emit(eventName: string, data: any){
    
    
    this.socket.emit(eventName, data);
  }

  SendChatCommit(eventName:string){
    return new Observable((subscriber) => {
      this.socket.on(eventName, (data) => {
       
        
        subscriber.next(data);
      })
    });
  }



}
