import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FaqService } from 'src/app/services/faq.service';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactusComponent implements OnInit {

  myReactiveForm: FormGroup;
  userData: any = {};
  dataTitle: string = "Contact Us";
  resData: any = "";
  showMsg: boolean = false;

  constructor(private _myUser: FaqService) { }

  ngOnInit(): void {
    this.myReactiveForm = new FormGroup({
      'name': new FormControl(null, [Validators.required]),
      'email': new FormControl(null, [Validators.required, Validators.email]),
      'subject': new FormControl(null, [Validators.required]),
      'msg': new FormControl(null, [Validators.required]),
    })
  }
  onSubmit() {
    ;
    if (this.myReactiveForm.valid) {
      this.userData = this.myReactiveForm.value;
      this._myUser.saveData(this.userData).subscribe((response_data) => {
        this.resData = response_data['response_message'];
      })
      this.myReactiveForm.reset();
      this.showMsg = true
    } else {
      let key = Object.keys(this.myReactiveForm.controls)
      key.filter(data => {
        let control = this.myReactiveForm.controls[data];
        if (control.errors != null) {
          control.markAsTouched();
        }
      })
   
    }

  }

}
