

<header id="stickyHeader">
  <div class="container homepage">
    <div class="nav">
      <div class="logo">
        <a href="#">
          <img alt="logo" src="assets/img/logo-cbc.png" width="70">
        </a>
        <div class="line"></div>
        <ul>
          <li><a href="#about">about</a></li>
          <li><a href="#Services">Features</a></li>
          <li><a href="#team">team</a></li>
          <li><a href="#faq1">FAQ</a></li>
          <li class="pe-0"><a href="#/contact">Contact Us</a></li>
        </ul>
      </div>
      <div class="callto d-none">
        <a href="callto:+12344502086">+1234 450 2086</a>
        <i>
          <svg fill="none" height="112" viewBox="0 0 24 24" width="112" xmlns="http://www.w3.org/2000/svg">
            <g clip-rule="evenodd" fill="rgb(0,0,0)" fill-rule="evenodd">
              <path
                d="m7 2.75c-.41421 0-.75.33579-.75.75v17c0 .4142.33579.75.75.75h10c.4142 0 .75-.3358.75-.75v-17c0-.41421-.3358-.75-.75-.75zm-2.25.75c0-1.24264 1.00736-2.25 2.25-2.25h10c1.2426 0 2.25 1.00736 2.25 2.25v17c0 1.2426-1.0074 2.25-2.25 2.25h-10c-1.24264 0-2.25-1.0074-2.25-2.25z" />
              <path
                d="m10.25 5c0-.41421.3358-.75.75-.75h2c.4142 0 .75.33579.75.75s-.3358.75-.75.75h-2c-.4142 0-.75-.33579-.75-.75z" />
              <path
                d="m9.25 19c0-.4142.33579-.75.75-.75h4c.4142 0 .75.3358.75.75s-.3358.75-.75.75h-4c-.41421 0-.75-.3358-.75-.75z" />
            </g>
          </svg>
        </i>
      </div>
    </div>
  </div>
</header>

<section class="hero-section">
  
<h2 mat-dialog-title class="color-dd-gradient font-weight-bold">Reset Password</h2>
<div class="container homepage reset-password">
  <div class="row">
    <div class="input-group mb-3 shadow border-40 my-4 PasswordInput">
      <input type="password" class="form-control border-40 border-right-0 pl-5 passwordField" 
    
      placeholder="Enter new password" aria-describedby="basic-addon2"
      #currentPassword>
      <div class="input-group-append" style="height: 67px;">
        <span class="input-group-text border-40 border-left-0 bg-white pr-4" id="basic-addon2">
            <img height="43px" src="assets/img/lock.png" alt="" />
        </span>
      </div>
    </div>
    <div class="input-group mb-3 shadow border-40 my-4 PasswordInput">
      <input type="password" class="form-control border-40 border-right-0 pl-5 passwordField" 
      placeholder="Confirm new password" aria-describedby="basic-addon2"
      #newPassword>
      <div class="input-group-append" style="height: 67px;">
        <span class="input-group-text border-40 border-left-0 bg-white pr-4" id="basic-addon2">
            <img height="43px" src="assets/img/lock.png" alt="" />
        </span>
      </div>
    </div>
   
  </div>
  <div class="row justify-content-center mx-0">
    <button type="button" class="btn btn-theme border-40"
    (click)="submitChangePassword()">Submit</button>
  </div>
</div>
</section>
<!-- <div mat-dialog-actions align="end">
  <button mat-button (click)="onNoClick()">Close</button>
</div> -->
<style>
  .container.homepage.reset-password {
	background: #fff;
	padding: 70px;
  max-width: 600px;
}
#mat-dialog-title-0 {
	color: #fff !important;
	background-color: #fff !important;
	background-image: initial;
}
.container.homepage {
	max-width: 1320px;
}
</style>