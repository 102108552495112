<div class="text-right">
  <img
    style="height: 20px; width: 20px; object-fit: cover; margin-left: 0"
    src="./assets/img/close.jpg"
    alt=""
    (click)="close()"
  />
</div>
<div
  class="row mx-0 h-100"
  style="position: relative; z-index: 999993 !important"
>
  <div class="col-md-12 px-0 bg-white">
    <div class="createform mt-3 align-items-center">
      <div class="createtitle">
        <h3 class="ml-0 mb-0">Create Group</h3>
      </div>
      <!-- Nav tabs -->

      <div class="createtitleInput">
        <input
          class="form-control"
          [(ngModel)]="groupName"
          placeholder="Enter Group Name"
        />
      </div>
      <!-- Tab panes -->
    </div>

    <h3 class="conmtactylost">Contact List</h3>
    <div style="max-height: 250px; overflow-y: scroll">
      <div class="container w-100 mw-100">
        <div *ngFor="let contact of contactList">
          <div
            class="row bg-white border-bottom py-2"
            *ngIf="contact.Issync === true"
          >
            <div class="col-4">
              {{ contact.RemoteUserFirstName }}({{ contact.phone }})
            </div>
            <div class="col-2" (click)="addgroup(contact)">
              <input type="checkbox" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="text-right mt-3" (click)="submitgroup()">
      <button class="btn btn-success">Create Group</button>
    </div>
  </div>
</div>
