import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

    function sidebarCollapse() {
      var $containerWidth = $(window).width();
      $('#sidebarCollapse').hide();
      if ($containerWidth < 1024) {
        $('#sidebar').hide();
        $('#sidebarCollapse').show();
        $('#sidebarCollapse, #sidebar .nav-item .nav-link').on(
          'click',
          function () {
            $('#sidebar').toggle();
          }
        );
      } else {
        $('#sidebar').show();
      }
      $('#sidebar .nav-item .nav-link').click(function () {
        $('#sidebar .nav-item .nav-link.activeTab').removeClass('activeTab');
        $(this).addClass('activeTab');
      });
    }
    $(document).ready(function () {
      sidebarCollapse();
    });
    $(window).resize(function () {
      sidebarCollapse();
    });

  }

}
