<app-header></app-header>

<div class="container body-content-wrpper w-100 mw-100">
    <div class="row">        
        <div class="col-lg-3 homepggae">
            <app-sidebar></app-sidebar>
        </div>
        <div class="col-lg-9 homepggae">
            <div class="row bg-cal-theme faq_dec">
                <div class="tab-content w-100">
                    <div class="tab-pane active h-100" id="getStart">
                        <router-outlet></router-outlet>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>