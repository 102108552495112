import { HttpClient, HttpHeaders,HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';


@Injectable({
  providedIn: 'root'
})
export class FaqService {
  baseChatUrl:string = environment.apiChatUrl;
  baseApiUrl:string = environment.apiUrl;
  constructor(private http: HttpClient) { }


  url = this.baseApiUrl+'/privacy-policy?';
  faqurl = this.baseApiUrl+'/faq?';
  urlOne = this.baseApiUrl+'/contact';
  urlContact = this.baseApiUrl+'/contactList';
  _resetPassword = this.baseApiUrl+'/resetPassword';

  header = new HttpHeaders({
    'Accept' : 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Headers': 'Content-Type',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': 'true',
    'Access-Control-Allow-Methods': 'POST',
    // 'ApplicationType': 'BROWSER',
  });



  faqData(): Observable<any> {
    return this.http.get(this.faqurl);
  }

  saveData(userData: any = {}) {
    return this.http.post(this.urlOne, userData);
  }

  contactData(contactList: any) {
    return this.http.post(this.urlContact, contactList);
  }

  resetPassword(_id, password, confirmPassword): Observable<HttpResponse<any>> {
    console.log(_id, password, confirmPassword);
    return this.http.post<HttpResponse<any>>(this._resetPassword, {
      _id,
      password,
      confirmPassword
    }, {
      headers: this.header,
      observe: 'response'
    })
  }


}

