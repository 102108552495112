import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';
import { Observable } from "rxjs";
import { CredentialService } from './credential.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  baseUrl:string = environment.apiUrl;
  
  header = new HttpHeaders({
    'Accept' : 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Headers': 'Content-Type',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': 'true',
    'Access-Control-Allow-Methods': 'POST',
    // 'ApplicationType': 'BROWSER',
  });

  private _loginUser = this.baseUrl+'/login';
  private _logoutUser = this.baseUrl+'/logout';
  private _forgotPassword = this.baseUrl+'/forgotPassword';
  private _forgotUsername = this.baseUrl+'/forgotUsername';
  private _resetPassword = this.baseUrl+'/resetPassword';

  constructor(private http: HttpClient,
    private _credentials : CredentialService) {}

  /** User Login */

  userLogin(user): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(this._loginUser, {
      appType : 'BROWSER',
      deviceToken: '123456',
      userName: user.userName.trim(),
      password: user.password.trim()
    }, {
      headers: this.header,
      observe: 'response'
    })
  }

  resetPassword(_id, password, confirmPassword): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(this._resetPassword, {
      _id,
      password,
      confirmPassword
    }, {
      headers: this.header,
      observe: 'response'
    })
  }

  /** Auth Checking */
  
  isAuthenticated(): boolean {
    if (localStorage.getItem('auth_data')) {
      this._credentials.setCredentials(JSON.parse(localStorage.getItem('auth_data')));
      return true;
    }
    return false;
  }

  /** User Logout */

  userLogout(): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(this._logoutUser, {
      headers: this.header,
      observe: 'response'
    })
  }

  /** Forgot Password */

  forgotPassword(email): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(this._forgotPassword, {
      email
    }, {
      headers: this.header,
      observe: 'response'
    })
  }


  /** Forgot Username */
  forgotUsername(email): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(this._forgotUsername, {
      email
    }, {
      headers: this.header,
      observe: 'response'
    })
  }


  changePassword(_id, currentPassword, newPassword): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(this._forgotPassword, {
      _id,
      currentPassword,
      newPassword
    }, {
      headers: this.header,
      observe: 'response'
    })
  }




}
