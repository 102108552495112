import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { 
  AuthGuardService as AuthGuard 
} from './services/auth-guard.service';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { CalendarComponent } from './components/dashboard/calendar/calendar.component';
import { TeamComponent } from './components/dashboard/team/team.component';
import { NotificationComponent } from './components/dashboard/notification/notification.component';
import { CallsComponent } from './components/dashboard/calls/calls.component';
import { CallLogComponent } from './components/dashboard/call-log/call-log.component';
import { ChatComponent } from './components/dashboard/chat/chat.component';
import { ContactComponent } from './components/dashboard/contact/contact.component';
import { GroupComponent } from './components/dashboard/group/group.component';
import { DialingComponent } from './components/dialing/dialing.component';
import { receivingcallComponent } from './components/receivingcall/receivingcall.component';
import { GroupVideoCallComponent } from './components/group-video-call/group-video-call.component';
import { ContactusComponent } from './components/dashboard/contactus/contactus.component';
import { profileComponent } from './components/dashboard/profile/profile.component';
import { FaqComponent } from './components/faq/faq.component';
import {ContactUsComponent} from './components/contactus/contactus.component';
import { TermserviceComponent } from './components/termservice/termservice.component';


const routes: Routes = [
  { path: '', component: LoginComponent, children: [
    { path: '', redirectTo: 'login', pathMatch: 'full'},
  ]},
  { path: 'reset-password/:token', component: ResetPasswordComponent},
  { path: 'privacy-policy', component: TermserviceComponent},
  { path: 'faq', component: FaqComponent},
  { path: 'contact', component: ContactUsComponent},
  { path: 'dialing', component: DialingComponent},
  { path: 'receivingcall', component: receivingcallComponent},

  { path: 'group-video-call', component: GroupVideoCallComponent},
 
  {path: 'dashboard', component: DashboardComponent,   canActivate: [AuthGuard],
    children: [
    {path: '', redirectTo: 'notification', pathMatch: 'full'},
    { path: 'notification', component: NotificationComponent},
    // { path: 'calendar', component: CalendarComponent},
    { path: 'team', component: TeamComponent},
    { path: 'calls', component: CallsComponent,   canActivate: [AuthGuard]},
    { path: 'call-log', component: CallLogComponent},
    { path: 'chat', component: ChatComponent,   canActivate: [AuthGuard]},
    { path: 'contact', component:ContactComponent,canActivate: [AuthGuard]},
    { path: 'group', component:GroupComponent,   canActivate: [AuthGuard]},
    { path: 'contactus', component:ContactusComponent,   canActivate: [AuthGuard]},
    { path: 'profile', component:profileComponent,   canActivate: [AuthGuard]},
  ]},
  {
    path: '**', redirectTo: 'dashboard/contact', pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
