

<header id="stickyHeader">
    <div class="container homepage">
      <div class="nav">
        <div class="logo">
          <a href="#">
            <img alt="logo" src="assets/img/logo-cbc.png" width="70">
          </a>
          <div class="line"></div>
          <ul id="top-menu">
            <li><a href="#about-us">about</a></li>
            <li><a href="#Services" >Features</a></li>
            <li><a href="#team">team</a></li>
            <li><a href="#faq1">FAQ</a></li>
            <li class="pe-0"><a href="#contact">Contact Us</a></li>
          </ul>
        </div>
        <div class="callto d-none">
          <a href="callto:+12344502086">+1234 450 2086</a>
          <i>
            <svg fill="none" height="112" viewBox="0 0 24 24" width="112" xmlns="http://www.w3.org/2000/svg">
              <g clip-rule="evenodd" fill="rgb(0,0,0)" fill-rule="evenodd">
                <path
                  d="m7 2.75c-.41421 0-.75.33579-.75.75v17c0 .4142.33579.75.75.75h10c.4142 0 .75-.3358.75-.75v-17c0-.41421-.3358-.75-.75-.75zm-2.25.75c0-1.24264 1.00736-2.25 2.25-2.25h10c1.2426 0 2.25 1.00736 2.25 2.25v17c0 1.2426-1.0074 2.25-2.25 2.25h-10c-1.24264 0-2.25-1.0074-2.25-2.25z" />
                <path
                  d="m10.25 5c0-.41421.3358-.75.75-.75h2c.4142 0 .75.33579.75.75s-.3358.75-.75.75h-2c-.4142 0-.75-.33579-.75-.75z" />
                <path
                  d="m9.25 19c0-.4142.33579-.75.75-.75h4c.4142 0 .75.3358.75.75s-.3358.75-.75.75h-4c-.41421 0-.75-.3358-.75-.75z" />
              </g>
            </svg>
          </i>
        </div>
      </div>
    </div>
  </header>
  <section class="hero-section">
    <div class="container homepage">
      <div class="row align-items-center">
        <div class="col-xl-6">
          <div class="hero-section-text">
            <h1>CBC Provides amazing and outstanding features</h1>
            <p>The CBC app automatically backs up and restores your contacts and gives you instant access to your contacts with and without your phone anywhere in the world using any other mobile phone, laptop or iPod with no setup required</p>
            <p>This is what makes the app unique.</p>
            <div class="video">
              <div class="play-button">
                <a data-fancybox="" href="https://youtu.be/mRjc3LQ5erI">
                  <i>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="35"
                      height="56" viewBox="0 0 35 56">
                      <defs>
                        <clipPath id="clip-video_arrow">
                          <rect width="35" height="56"></rect>
                        </clipPath>
                      </defs>
                      <g id="video_arrow" data-name="video arrow" clip-path="url(#clip-video_arrow)">
                        <path data-name="Shape 1" d="M1362,5000.8,1327,4972V5027Z"
                          transform="translate(-1326.998 -4971.996)" fill="rgba(0,0,0,0)"></path>
                        <path id="Shape_1_-_Outline" data-name="Shape 1 - Outline"
                          d="M1333,5015.017l19.29-14.437L1333,4984.7v30.313M1327,5027V4972l35,28.807Z"
                          transform="translate(-1326.998 -4971.996)"></path>
                      </g>
                    </svg>
                  </i>
                  <span>
                    Watch Overview
                  </span>
                </a>
              </div>
              <div class="review">
                <h2>4.9 <span>out of 5</span></h2>
                <ul class="star">
                  <li><i class="fa-solid fa-star"></i></li>
                  <li><i class="fa-solid fa-star"></i></li>
                  <li><i class="fa-solid fa-star"></i></li>
                  <li><i class="fa-solid fa-star"></i></li>
                  <li><i class="fa-solid fa-star"></i></li>
                </ul>
                <img alt="img" src="assets/img/google.png">
              </div>
            </div>
            <img alt="img" class="dots" src="assets/img/dots.png">
            <img alt="img" class="landing-slider" src="assets/img/landing-slider.png">
          </div>
        </div>
        <div class="col-xl-6">
          <form role="form" class="get-a-quote" [formGroup]="loginForm">
            <div class="mb-lg-5 mb-3 d-flex align-items-center justify-content-between top-form-wrap">
              <div class="welcome-wrap">
                <p class="p-0">Welcome</p>
                <h2>Please Login</h2>
              </div>
              <img alt="logo" src="assets/img/logo-cbc-2.png" width="75">
            </div>
            <div class="group-img">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M15.364 11.636C14.3837 10.6558 13.217 9.93013 11.9439 9.49085C13.3074 8.55179 14.2031 6.9802 14.2031 5.20312C14.2031 2.33413 11.869 0 9 0C6.131 0 3.79688 2.33413 3.79688 5.20312C3.79688 6.9802 4.69262 8.55179 6.05609 9.49085C4.78308 9.93013 3.61631 10.6558 2.63605 11.636C0.936176 13.3359 0 15.596 0 18H1.40625C1.40625 13.8128 4.81279 10.4062 9 10.4062C13.1872 10.4062 16.5938 13.8128 16.5938 18H18C18 15.596 17.0638 13.3359 15.364 11.636ZM9 9C6.90641 9 5.20312 7.29675 5.20312 5.20312C5.20312 3.1095 6.90641 1.40625 9 1.40625C11.0936 1.40625 12.7969 3.1095 12.7969 5.20312C12.7969 7.29675 11.0936 9 9 9Z"
                  fill="#555555"></path>
              </svg>
              <input type="text" id="exampleInputEmail1"
              formControlName="userName" placeholder="User ID" required>
            </div>
            <div class="group-img">
              <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M15.8649 18H6.13513C2.58377 18 0.540527 15.9568 0.540527 12.4054V5.5946C0.540527 2.04324 2.58377 0 6.13513 0H15.8649C19.4162 0 21.4595 2.04324 21.4595 5.5946V12.4054C21.4595 15.9568 19.4162 18 15.8649 18ZM6.13513 1.45946C3.35242 1.45946 1.99999 2.81189 1.99999 5.5946V12.4054C1.99999 15.1881 3.35242 16.5406 6.13513 16.5406H15.8649C18.6476 16.5406 20 15.1881 20 12.4054V5.5946C20 2.81189 18.6476 1.45946 15.8649 1.45946H6.13513Z"
                  fill="#444444"></path>
                <path
                  d="M10.9988 9.8465C10.1815 9.8465 9.35452 9.59352 8.72208 9.07785L5.67668 6.64539C5.36532 6.39241 5.30696 5.93511 5.55992 5.62376C5.8129 5.31241 6.2702 5.25403 6.58155 5.50701L9.62695 7.93947C10.3664 8.53298 11.6215 8.53298 12.361 7.93947L15.4064 5.50701C15.7178 5.25403 16.1848 5.30268 16.428 5.62376C16.681 5.93511 16.6324 6.40214 16.3113 6.64539L13.2659 9.07785C12.6432 9.59352 11.8161 9.8465 10.9988 9.8465Z"
                  fill="#444444"></path>
              </svg>
              <input type="password" name="Password" id="exampleInputPassword1"
              formControlName="password" placeholder="Password" required>
            </div>
            <div class="d-flex justify-content-between formFields">
              <div class="d-flex align-items-center">
                <input type="checkbox" value="lsRememberMe" formControlName="rememberMe" style="width: 18px; height: 18px;">
                <label for="Remember" class="ps-2 pt-1 lsRememberMe">Remember me</label>
              </div> <br>
              <p><a class="pe-2" href="javascript:void(0);" style="font-weight: 600;cursor: pointer;" (click)="forgotPassword()"> Forgot password?</a></p>
            </div>
            <div class="form-group">
              <ngx-recaptcha2
              #captchaElem
              siteKey="{{siteKey}}"
              (success)="handleSuccess($event)"
              [size]="size"
              [hl]="lang"
              [theme]="theme"
              [type]="type"
              formControlName="recaptcha"
            >
            </ngx-recaptcha2>
          </div>
            <button type="submit" class="btn batton mt-2 text-center"
              style="width: 100%; justify-content: center;" (click)="userLogin()">Login</button>
          </form>
        </div>
      </div>
    </div>
  </section>
  <div class="d-flax">
    <div class="container-fluid">
      <div class="check">
        <div class="state">
          <img alt="img" src="assets/img/chak.png">
          <h6>Contact Backup</h6>
        </div>
        <div class="state">
          <img alt="img" src="assets/img/chak.png">
          <h6>Contact Restore</h6>
        </div>
        <div class="state">
          <img alt="img" src="assets/img/chak.png">
          <h6>Call &amp; Chat</h6>
        </div>
      </div>
    </div>
  </div>
  <section id="about-us" class="gap pb-0">
    <div class="container homepage">
      <div class="row align-items-center">
        <div class="col-lg-7">
          <div class="heading">
            <span>How CBC Works?</span>
            <div class="headingline"></div>
            <h2>Backing up contacts at ease</h2>
          </div>
          <div class="we-are">
            <p>
              The mobile application allows the users to send and receive SMS and call from the contact list by using
              either application to application, or application to mobile call. CBC App offers its users easy access
              anywhere in the world to their contacts if you have access to the internet and a telephone system. CBC
              application has a “Restore Access” to restore the contacts, a “Backup Access” to initialize the automatic
              backup after upgrading to a new version hardware/New phone, and a search access to search by names or by
              area code.
            </p>

          </div>
        </div>
        <div class="col-lg-5">
          <div class="we-are-img">
            <img alt="we-are" src="assets/img/we-are.jpg">
          </div>
        </div>
      </div>
      <div class="business-performance d-none">
        <div class="row align-items-center">
          <div class="col-lg-4">
            <div class="business-performance-text">
              <h4>Business Performance Increases</h4>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="customers-performance">
              <div class="performance">
                <div class="performance-count">
                  <h2 class="timer count-title count-number" data-to="10" data-speed="1500"></h2>
                  <span>k<sup>+</sup></span>
                </div>
                <p>Happy Satisfied<br> Customers</p>
              </div>
              <div class="performance">
                <div class="performance-count">
                  <h2 class="timer count-title count-number" data-to="31" data-speed="1500"></h2>
                  <span>m<sup>+</sup></span>
                </div>
                <p>Completed<br> Downloads</p>
              </div>
              <div class="performance">
                <div class="performance-count">
                  <h2 class="timer count-title count-number" data-to="4" data-speed="1500"></h2>
                  <span>k</span>
                </div>
                <p>Expert Team <br> Members</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="gap pb-0">
    <div class="container homepage">
      <div class="discount-offer" style="background-image: url(assets/img/map.png);">
        <div id="carouselExampleSlidesOnly" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-inner">
            
            <carousel [interval]="3000"  [showIndicators]="false" [startFromIndex]="0" [isAnimated]="true"  [noPause] ="false" [direction]="'left'" class="custom-carousel">
              <slide data-slide-to="0">
                <img class="d-block w-100" alt="discount" src="assets/img/discount.png">
              </slide>
              <slide data-slide-to="1">
                <img class="d-block w-100" alt="discount" src="assets/img/discount-3.png">
              </slide>
              <slide data-slide-to="2">
                <img class="d-block w-100" alt="discount" src="assets/img/discount-2.png">
              </slide>
            </carousel>
          </div>
        </div>
        <!--  <img alt="discount" src="assets/img/discount.png"> -->
        <div class="downlaodcbc">
          <h2>Download CBC app now! </h2>
          <p>Start right away for a better cause and rest assured, your contacts are safe and sound with us</p>
          <a class="batton" style="font-weight: 600;" href="https://play.google.com/store/apps/details?id=com.cbc">Get
            on Playstore</a>
          <a class="batton ms-3 btnMD" style="font-weight: 600;"
            href="https://apps.apple.com/mu/app/cellular-backup-contacts/id1552344692">Get on Appstore</a>
        </div>
      </div>
    </div>
  </section>
  <section id="Services" class="some-features gap">
    <div class="container homepage">
      <div class="heading">
        <span>Some features</span>
        <div class="headingline"></div>
        <h2>Notable features of CBC App</h2>
      </div>
      <div class="row">

        <div class="col-lg-3 col-md-6">
          <div class="creative-design">
            <img alt="shaps" src="assets/img/some-features-shaps-1.png">
            <h5>Backup contacts</h5>
            <p>We are all aware of how baffling it can be to lose your contacts. Some of the contacts may be really
              important for personal and/or professional needs.</p>
          </div>
          <div class="creative-design shaps-2 mb-xl-0">
            <img alt="shaps" src="assets/img/some-features-shaps-2.png">
            <h5>Contact Restore</h5>
            <p>Backed up contacts can be restored at any time required. Even if the contacts are deleted it can restored
              using CBC’s restore option.</p>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 ps-xl-4">
          <div class="creative-design shaps-3">
            <img alt="shaps" src="assets/img/some-features-shaps-3.png">
            <h5>Call & Chat</h5>
            <p>Enjoy free voice calls on CBC for both app to app and app to mobile calls and discover many features to
              help you stay connected with the people in your contact list.</p>
          </div>
          <div class="creative-design shaps-4 mb-0">
            <img alt="shaps" src="assets/img/some-features-shaps-4.png">
            <h5>Chat as you go</h5>
            <p>Stay connected with friends and keep the conversation flowing, no matter where life takes you.</p>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="some-features-img">
            <img alt="img" src="assets/img/some-features.png">
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="team" class="expert-team gap" style="background-color: #f3f6fa;">
    <div class="container homepage">
      <div class="heading">
        <span>Meet Our Expert Team</span>
        <div class="headingline"></div>
        <div class="team-review">
          <h2>Our experts</h2>
          <div class="team-review-star">
            <ul class="star">
              <li><i class="fa-solid fa-star"></i></li>
              <li><i class="fa-solid fa-star"></i></li>
              <li><i class="fa-solid fa-star"></i></li>
              <li><i class="fa-solid fa-star"></i></li>
              <li><i class="fa-solid fa-star"></i></li>
            </ul>
            <p>4.9 out of 5</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <div class="team-expert">
            <img alt="img" src="assets/img/team-1.jpg">
            <div>
              <span>Expert Consultant</span>
              <h4>Thomas Williams</h4>
              <p>CBC boasts an intuitive and clean design, making navigation a breeze. Whether you're a tech whiz or
                not, you'll find yourself comfortably exploring the app in no time.</p>
              <a class="d-flex align-items-center" href="#"><i class="fa-brands fa-facebook-f"></i>facebook</a>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="team-expert mb-0">
            <img alt="img" src="assets/img/team-2.jpg">
            <div>
              <span>Expert Consultant</span>
              <h4>Jessica Jamson</h4>
              <p>One of the standout features is the app's reliability on the go. No matter where I am, I can keep track
                of the contacts and backup contacts effortlessly.</p>
              <a class="d-flex align-items-center" href="#"><i class="fa-brands fa-facebook-f"></i>facebook</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="faq1" class="asked-questions gap no-top">
    <div class="container homepage">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="questions-img">
            <img alt="questions" src="assets/img/questions.jpg">
            <img alt="img" class="dots" src="assets/img/dots.png">
          </div>
        </div>
        <div class="col-lg-6">
          <div class="heading">
            <div class="d-flex align-items-center">

              <h6>frequently asked questions</h6>
              <div class="headingline"></div>
            </div>
            <h2>Got questions? We’ve got answers.</h2>
          </div>
          <div class="accordion">
            <div class="accordion-item">
              <a href="#" class="heading">
                <div class="icon"></div>
                <div class="title">How does CBC work?</div>
              </a>
              <div class="content">
                <p>The CBC application does not require any configuration to access your contacts to other mobile phones
                  to call/video/conference and send SMS. CBC backups your contacts and can be restored anytime, on any
                  device as required. CBC provides a combined solution to transfer all your contacts from any android /
                  iPhone device to another iPhone / Android device. Make calls to your offline friend using the
                  ‘application to mobile’ call feature. Chat with your contacts as needed by sharing your files at the
                  flick of a
                  switch.
                </p>
              </div>
            </div>
            <div class="accordion-item active">
              <a href="#" class="heading">
                <div class="icon"></div>
                <div class="title">How does restoring contacts work?</div>
              </a>

              <div class="content" style="display: block;">
                <p>CBC application has a “Restore Access” to restore the contacts, a “Backup Access” to initialize the
                  automatic backup after upgrading to new version hardware, and a search access to search by names or by
                  area code.
                </p>
              </div>
            </div>
            <div class="accordion-item">
              <a href="#" class="heading">
                <div class="icon"></div>
                <div class="title">How are app to app calls made?</div>
              </a>
              <div class="content">
                <p>The CBC application allows users to receive or place audio or video calls on their device. A CBC user
                  can make phone calls and video calls at ease from their phone contacts all across the globe absolutely
                  free through internet connection
                </p>
              </div>
            </div>
            <div class="accordion-item">
              <a href="#" class="heading">
                <div class="icon"></div>
                <div class="title">How are App to voice calls made?</div>
              </a>
              <div class="content">
                <p>The CBC application allows users to call through the internet even if the recipient caller does not
                  have CBC installed in their phone.So users can use CBC application as default calling application in
                  their phone. It doesn't matter whether the receiver has CBC installed or not.
                </p>
              </div>
            </div>
            <div class="accordion-item">
              <a href="#" class="heading">
                <div class="icon"></div>
                <div class="title">Is my data secure?</div>
              </a>
              <div class="content">
                <p>Yes. absolutely...CBC application keeps each and every user's data safe online by following data
                  protection law as per the standards
                </p>
              </div>
            </div>

            <div class="accordion-item">
              <a href="#" class="heading">
                <div class="icon"></div>
                <div class="title">What if my backups are gone?</div>
              </a>
              <div class="content">
                <p>Even though you lose all your contacts from your phone, you can restore all of them from CBC by the
                  touch of a few fingertips. In CBC we always keep your data CBC always keeps your data unless you
                  delete your account.
                </p>
              </div>
            </div>

            <div class="accordion-item">
              <a href="#" class="heading">
                <div class="icon"></div>
                <div class="title">Does CBC save my data?</div>
              </a>
              <div class="content">
                <p>All your data is absolutely safe and it is stored in the cloud. CBC personally does not store any
                  personal information in the database
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section style="background-color: #f3f6fa;" class="gap clients-review">
    <div class="container homepage clients-review">
      <div class="row">
        <div class="col-lg-4">
          <div class="heading">
            <span>Client’s Reviews</span>
            <div class="headingline"></div>
            <h2>What our awesome clients say</h2>
          </div>
        </div>
        <div class="col-lg-8">
          <div class="client-reviews">
            <img alt="img" class="dots" src="assets/img/dots.png">
            <i><img alt="img" class="question" src="assets/img/question.png"></i>
            <img alt="img" class="clients" src="assets/img/client-img-2.jpg">
            <div>
              <h6>CBC boasts an intuitive and clean design, making navigation a breeze. Whether you're a tech whiz or
                not, you'll find yourself comfortably exploring the app in no time.</h6>
              <h4>Jessica James</h4>
              <span>CEO, Sparkout</span>
            </div>
          </div>
        </div>
        <div class="offset-xl-3 col-lg-9">
          <div class="client-reviews two mb-0">
            <img alt="img" class="dots" src="assets/img/dots.png">
            <i><img alt="img" class="question" src="assets/img/question.png"></i>
            <img alt="img" class="clients" src="assets/img/client-img-1.jpg">
            <div>
              <h6>Class aptent taciti sociosqu ad litora torquent per conubia nos tra, per inceptos himenaeos. Se d vel
                arcu vitae ex tincidunt dit a id lorem.</h6>
              <h4>Jonson Walkar</h4>
              <span>Founder, Mantle Network</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Newsletter new start -->
  <!-- <section class="newsletteR gap pb-0">
    <div class="newsletterWrapper text-center d-flex justify-content-center">
      <div class="container homepage">
        <div class="heading">
          <span>Stay in touch</span>
          <div class="d-flex justify-content-center">
            <div class="headingline "></div>
          </div>
          <h2 style="width: 100%;">Subscribe to our newsletter</h2>
        </div>
        <div class="row  d-flex justify-content-center">
          <div class="col-lg-6">
            <form action="#" class="newsletter__info--field">
              <input type="email" placeholder="Email address" class="input-field">
              <button>Subscribe</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section> -->
  <!-- Newsletter new end -->

  <section>
    <div id="contact1" class="gap no-bottom">
      <div class="container homepage address-page">
        <div class="offset-xl-2 offset-lg-1 col-xl-10">
          <div class="location ">
            <div class="address d-none">
              <i>
                <svg height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg">
                  <g id="pin">
                    <path
                      d="m12 22c-.3632813 0-.6972656-.1967773-.8740234-.5141602l-2.3066406-4.1518555c-2.897461-1.2597655-4.819336-4.1606445-4.819336-7.3339843 0-4.4111328 3.5888672-8 8-8s8 3.5888672 8 8c0 3.1733398-1.921875 6.0742188-4.8193359 7.3339844l-2.3066406 4.1518555c-.1767579.3173828-.5107422.5141601-.8740235.5141601zm0-18c-3.3085938 0-6 2.6914063-6 6 0 2.4736328 1.5576172 4.7265625 3.8769531 5.605957.2207031.0839844.4052734.2431641.5195313.4492188l1.6035156 2.8857422 1.6035156-2.8857422c.1142578-.2060547.2988281-.3652344.5195313-.4492188 2.3193359-.8793945 3.8769531-3.1323242 3.8769531-5.605957 0-3.3085937-2.6914062-6-6-6zm0 9c-1.6542969 0-3-1.3457031-3-3s1.3457031-3 3-3 3 1.3457031 3 3-1.3457031 3-3 3zm0-4c-.5517578 0-1 .4487305-1 1s.4482422 1 1 1 1-.4487305 1-1-.4482422-1-1-1z" />
                  </g>
                </svg>
              </i>
              <h6>Address:</h6>
              <p>CBC, 71 Leonard Street<br> Boston, MA</p>
            </div>
            <div class="boder-line d-none"></div>
            <div class="address d-none">
              <i>
                <svg width="800px" height="800px" viewBox="-0.5 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16.0562 18.0437L6.45618 8.45374" stroke="#0F0F0F" stroke-miterlimit="10"
                    stroke-linecap="round" stroke-linejoin="round" />
                  <path
                    d="M9.94623 8.45373C11.1462 7.25373 11.1462 5.29373 9.94623 4.09373C8.49623 2.64373 6.15622 2.64373 4.71622 4.09373L3.40623 5.40374C2.20623 6.60374 2.20623 8.56374 3.40623 9.76374L5.14622 11.5037L12.9962 19.3537L14.7362 21.0937C15.9362 22.2937 17.8962 22.2937 19.0962 21.0937L20.4062 19.7837C21.8562 18.3337 21.8562 15.9937 20.4062 14.5537C19.2062 13.3537 17.2462 13.3537 16.0462 14.5537"
                    stroke="#0F0F0F" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                  <path
                    d="M13.9363 7.39374C14.6963 7.39374 15.4663 7.68374 16.0563 8.27374C16.6463 8.86374 16.9363 9.63374 16.9363 10.3937"
                    stroke="#0F0F0F" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                  <path
                    d="M14.6462 4.18372C16.0462 4.18372 17.4563 4.72372 18.5363 5.79372C19.6063 6.86372 20.1462 8.28372 20.1462 9.68372"
                    stroke="#0F0F0F" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </i>
              <h6>Phone:</h6>
              <p>Home:<a href="callto:02078717662">+1 234567890</a></p>
              <p>Office:<a href="callto:02078717662">+1 234567890</a></p>
            </div>
            <div class="boder-line d-none"></div>
            <div class="address mb-0 d-flex align-items-center">
              <div class="infoHead">
                <i>
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                    width="256" height="256" viewBox="0 0 256 256" xml:space="preserve">
                    <defs>
                    </defs>
                    <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;"
                      transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
                      <path
                        d="M 89.981 6.2 C 90 6.057 90.001 5.915 89.979 5.775 c -0.003 -0.021 -0.001 -0.041 -0.005 -0.062 c -0.033 -0.163 -0.098 -0.317 -0.183 -0.462 c -0.009 -0.016 -0.01 -0.033 -0.019 -0.049 c -0.015 -0.024 -0.039 -0.036 -0.055 -0.059 c -0.034 -0.048 -0.06 -0.102 -0.101 -0.146 c -0.051 -0.056 -0.113 -0.097 -0.17 -0.144 c -0.031 -0.025 -0.058 -0.054 -0.09 -0.076 c -0.134 -0.093 -0.28 -0.164 -0.436 -0.209 c -0.028 -0.008 -0.056 -0.009 -0.084 -0.015 c -0.132 -0.03 -0.267 -0.041 -0.404 -0.034 c -0.046 0.002 -0.089 0.006 -0.135 0.012 c -0.039 0.006 -0.079 0.002 -0.118 0.01 l -87 19.456 c -0.611 0.137 -1.073 0.639 -1.159 1.259 c -0.085 0.62 0.224 1.229 0.775 1.525 l 23.523 12.661 l 7.327 23.36 c 0.008 0.025 0.025 0.043 0.034 0.067 c 0.021 0.056 0.052 0.106 0.08 0.16 c 0.059 0.114 0.127 0.218 0.211 0.312 c 0.022 0.025 0.03 0.057 0.054 0.08 c 0.022 0.021 0.05 0.028 0.073 0.048 c 0.099 0.086 0.207 0.155 0.325 0.213 c 0.047 0.023 0.088 0.053 0.136 0.07 c 0.164 0.061 0.336 0.1 0.517 0.1 c 0.011 0 0.022 0 0.033 0 c 0.179 -0.004 0.349 -0.044 0.509 -0.107 c 0.041 -0.016 0.075 -0.044 0.114 -0.063 c 0.127 -0.063 0.244 -0.139 0.349 -0.235 c 0.02 -0.018 0.046 -0.024 0.065 -0.044 l 12.009 -12.209 l 23.18 12.477 c 0.221 0.119 0.466 0.18 0.711 0.18 c 0.188 0 0.378 -0.035 0.557 -0.107 c 0.412 -0.164 0.73 -0.504 0.869 -0.926 L 89.93 6.473 c 0.014 -0.044 0.015 -0.09 0.025 -0.135 C 89.966 6.292 89.975 6.247 89.981 6.2 z M 77.435 10.018 L 25.58 36.717 L 5.758 26.047 L 77.435 10.018 z M 74.32 14.997 L 36.813 43.768 c -0.003 0.002 -0.005 0.006 -0.007 0.008 c -0.112 0.087 -0.209 0.194 -0.294 0.314 c -0.018 0.025 -0.035 0.05 -0.051 0.076 c -0.017 0.028 -0.039 0.052 -0.055 0.081 c -0.054 0.1 -0.093 0.204 -0.122 0.309 c -0.001 0.005 -0.005 0.009 -0.006 0.014 L 32.96 56.977 l -5.586 -17.809 L 74.32 14.997 z M 35.992 57.249 l 2.693 -10.072 l 4.717 2.539 L 35.992 57.249 z M 69.177 60.184 L 40.479 44.737 l 45.09 -34.588 L 69.177 60.184 z"
                        style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;"
                        transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                      <path
                        d="M 12.9 85.482 c -0.38 0 -0.76 -0.144 -1.052 -0.431 c -0.591 -0.581 -0.599 -1.53 -0.018 -2.121 l 14.292 -14.528 c 0.581 -0.592 1.531 -0.598 2.121 -0.018 c 0.591 0.581 0.599 1.53 0.018 2.121 L 13.97 85.034 C 13.676 85.333 13.288 85.482 12.9 85.482 z"
                        style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;"
                        transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                      <path
                        d="M 36.431 79.593 c -0.38 0 -0.76 -0.144 -1.052 -0.431 c -0.591 -0.581 -0.599 -1.53 -0.018 -2.121 l 14.291 -14.527 c 0.582 -0.591 1.531 -0.598 2.121 -0.018 c 0.591 0.581 0.599 1.53 0.018 2.121 L 37.501 79.145 C 37.207 79.443 36.819 79.593 36.431 79.593 z"
                        style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;"
                        transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                      <path
                        d="M 8.435 67.229 c -0.38 0 -0.76 -0.144 -1.052 -0.431 c -0.591 -0.581 -0.599 -1.53 -0.018 -2.121 l 10.445 -10.618 c 0.581 -0.591 1.531 -0.598 2.121 -0.018 c 0.591 0.581 0.599 1.53 0.018 2.121 L 9.505 66.78 C 9.211 67.079 8.823 67.229 8.435 67.229 z"
                        style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;"
                        transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                    </g>
                  </svg>
                </i>
              </div>
              <div class="detaIl ps-5">
                <h6>Email:</h6>
                <p><a class="ps-0" href="mailto:info@cbcapps.com">info@cbcapps.com</a></p>
                <p><a class="ps-0" href="mailto:office@cbcontacts.com">sales@cbcontacts.com</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <footer style="background-image: url(assets/img/footer.jpg);">
    <div class="container homepage">
      <div class="book-free">
        <img alt="paper-plan" src="assets/img/paper-plan.png">
        <h2>Book a Free Demo</h2>
        <p>Wondering how the CBC App works!. Let's connect</p>
        <a href="#contact" class="batton">Request a Demo</a>
        <ul>
          <li><a href="#"><i class="fa-brands fa-facebook-f"></i>facebook</a></li>
          <li><a href="#"><i class="fa-brands fa-instagram"></i>instagram</a></li>
          <li><a href="#"><i class="fa-brands fa-twitter"></i>twitter</a></li>
        </ul>
      </div>
      <p class="footer py-4" style="font-weight: 400;">2024 © CBC. Designed by <a href="#">All Rights Reserved</a></p>
    </div>
  </footer>

  <!-- Back to top button -->
  <a id="button"></a>

  <style>
* {
  margin: 0;
}
.container.homepage {
	max-width: 1320px;
  width: 100%;
  padding: 0 12px;
}
.container.homepage {
  width: 100% !important;
  margin: 0 auto;
}
.hero-section {
	margin: 0 !important;
	/* padding: 0 !important; */
}
.discount-offer h2 {
	color: #fff !important;
}
.custom-carousel .right.carousel-control.carousel-control-next {
	display: none !important;
}
.ms-3 {
    margin-left: 1rem;
}
.logo a {
	margin: initial !important;
}
.d-flex.align-items-center {
	margin-left: initial !important;
  margin-right: initial !important;
  margin-bottom: initial !important;
}
.ps-2 {
    padding-left: 0.5rem!important;
}
.top-form-wrap .welcome-wrap, .top-form-wrap img {
    margin: 0;
}
.top-form-wrap.d-flex.align-items-center {
    margin-bottom: 48px !important;
}
.top-form-wrap p, .top-form-wrap h2 {
    margin: 0 !important;
    padding: 0 !important;
}

.container.homepage.clients-review .offset-xl-3.col-lg-9 {
	margin-left: 25%;
}
.container.homepage.clients-review .client-reviews {
	margin-left: 60px;
	margin-bottom: 50px !important;
}
.container.homepage.address-page .offset-xl-2.offset-lg-1.col-xl-10 {
	margin-left: 16.666667%;
}
.container.homepage.address-page .location {
	margin-top: 25px;
}
@media (max-width: 1400px) {
.team-expert h4 {
    padding-top: 0 !important;
    font-weight: 600 !important;
    line-height: 1.2;
}

}

@media (max-width: 1200px) {
.discount-offer h2 {
    font-size: 34px ;
}

.creative-design h5 {
    font-size: 20px !important;
}
.creative-design p {
    font-size: 14px !important;
}
}

@media (max-width: 993px) {
  .play-button {
    margin: initial !important;
  }
  .hero-section-text {
	position: relative;

	margin: initial !important;
}
.review {
	
	margin: initial !important;
}
.discount-offer p {

	font-size: 16px !important;
}
.we-are p {
    font-size: 16px !important;
    line-height: 26px !important;
  }

.container.homepage {
    max-width: 960px;
  }
  .discount-offer {
	margin: initial !important;
}
.downlaodcbc{
  margin: initial !important;
}
#carouselExampleSlidesOnly {
	margin: initial !important;
}
  .heading h2 {
    font-size: 40px !important;
    line-height: 50px !important;
}

}
/* @media (min-width: 768px) {
  .container.homepage {
    max-width: 720px;
  }
} */
@media (max-width: 768px) {
  .container.homepage {
    max-width: 720px;
  }
  .heading h2 {
    font-size: 36px !important;
    line-height: 46px !important;
}



.client-reviews {
    flex-wrap: wrap;
}
.client-reviews img.clients {
    margin: initial !important;
    padding-bottom: 40px;
  }
  .client-reviews h6 {
    margin-bottom: 20px !important;
    width: 100%;
    font-size: 18px !important;
}
.book-free h2 {
  font-size: 36px !important;
    line-height: 46px !important;
    font-weight: 800 !important;
}
}

@media (max-width: 576px){
.container, .container-sm {
    max-width: 540px !important;
}
}
@media (max-width: 410px){
.hero-section-text h1 {
    font-size: 26px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    line-height: 1.2 !important;
}
}
@media (max-width: 480px){
.hero-section:before, .hero-section:after {
    height: 1461px !important;
    left: -17% !important;
    top: -32% !important;
}
}

@media (max-width: 450px){
h2 {
    font-size: 30px !important;
    line-height: 40px !important;
    font-family: 'Poppins' !important;
}
.heading h2 {
  font-size: 30px !important;
    line-height: 40px !important;
    font-family: 'Poppins' !important;
}
.discount-offer h2 {
    font-size: 26px !important;
    margin-right: 0;
    line-height: 36px !important;
}
}


  </style>