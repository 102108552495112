import { HttpResponse } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FaqService } from 'src/app/services/faq.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  @ViewChild('currentPassword') currentPassword : ElementRef;
  @ViewChild('newPassword') newPassword : ElementRef;
  id: string;

  constructor(private _priv: FaqService,  private toastr: ToastrService,private route: ActivatedRoute,private router: Router) {
      
    }

  ngOnInit(): void {
  }

  submitChangePassword() {
    console.log(this.route.snapshot.params.token);
    this._priv.resetPassword(
      this.route.snapshot.params.token,
      this.currentPassword.nativeElement.value,
      this.newPassword.nativeElement.value)
    .subscribe((response : HttpResponse<any>)=>{
      if(response.body.response_code == 2000) {
        this.toastr.success(response.body.response_message);
        this.router.navigate(['/login']);
      } else {
        this.toastr.error(response.body.response_message);
      }
    }, error => {
      this.toastr.error(error.error.message);
    });
  }


}
