import { Component, OnInit, Input,AfterViewInit,Inject,Optional } from '@angular/core';
import { MatDialogRef,MatDialog,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CredentialService } from 'src/app/services/credential.service';
import { ChatService } from 'src/app/services/chat.service';
import { HttpResponse } from '@angular/common/http';
import { contactList } from 'src/app/dataModel/contactList.model';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder } from '@angular/forms';
import { WebSocketService } from '../../services/web-socket.service';

export interface UsersData {
  groupName: string;
  groupid: number;
  userImage:string;
}

@Component({
  selector: 'app-Updategroupdetails',
  templateUrl: './grpdetails.component.html',
  styleUrls: ['./grpdetails.component.scss'],
})



export class GrpdetailsComponent implements OnInit {
  attachmentName:string;
  chatType:string;
  imgUrl:string;
  chatData:any=[];
  local_data:any;
  groupname:string;
  
  constructor(
  
    private _chat: ChatService,
    private _credential: CredentialService,
      private router: Router,
          public dialog: MatDialog,
          private toastr: ToastrService,
          private formBuilder: FormBuilder,
          private  WebSocketService:WebSocketService,

 @Optional() @Inject(MAT_DIALOG_DATA) public data: UsersData) {
    
    this.local_data = {...data};
  }

  ngOnInit(): void {
  

   this.groupname=this.local_data.groupName;
   this.imgUrl=this.local_data.userImage;

  }
 
  onSubmit() {

    
    let editgroup={
      "token": this._credential.credentials.chatToken,
      "groupName": (<HTMLInputElement>document.getElementById('name')).value,
      "groupId": this.local_data.groupid,
      "groupImage": this.imgUrl
    };
    this._chat.Editgroupdetails(
      editgroup)
      .subscribe((response) => {
        
        this.toastr.success(response.body.response_message);
      })

this.WebSocketService.emit("editgroup",editgroup);
      

  }

  close()
  {
    this.dialog.closeAll();
  }
  
  handleFileInput(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('attachment', file);
      formData.append('attachmentName','CBC-' + Date.now() +'-'+ file.type.split('/')[0]);
      formData.append('type',file.type.split('/')[0]);
      formData.append('userId',this._credential.credentials._id);
      formData.append('token',this._credential.credentials.chatToken);

      this._chat.fileUpload(formData).subscribe(
        (res) => {
          
          if (res) {
            this.imgUrl = res.response_data.attachment;
            this.attachmentName = res.response_data.attachmentName;
          }
        },
        (err) => console.log(err)
      );


    }
  }

}
