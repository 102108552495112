import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { CredentialService } from '../services/credential.service';
import { AuthService } from '../services/auth.service';
import { LoaderService } from '../services/loader.service';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
    accountType: any;
    
    constructor(private toastr: ToastrService,
        public router : Router,
        private _credentials: CredentialService,
        private _auth: AuthService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const currentUser = this._credentials.credentials;
        if (currentUser) {
            // 
            req = req.clone({
                setHeaders: { 
                    authtoken: `${currentUser.authToken}`
                }
            });          
        }

        // return next.handle(request);

        return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                // 
                if (event.status === 401) {
                    this.toastr.info("You have been automatically logged out for security reasons, please sign in to continue.");
                    this.router.navigate(['login'])
                    localStorage.removeItem('auth_data');
                    
                } else if(event.status == 404) {
                    this.toastr.warning('Page not found')
                    // this.router.navigate(['login'])
                } else if(event.status == 500) {
                    this.toastr.warning('Internal server error')
                    // this.router.navigate(['login'])
                }
            }
        }, (err: any) => {
            if (err instanceof HttpErrorResponse) {
                // 
                if (err.status === 401) {
                    this.toastr.info("You have been automatically logged out for security reasons, please sign in to continue.");
                    this.router.navigate(['login'])
                    localStorage.removeItem('auth_data');

                } else if (err.status == 404) {
                    this.toastr.warning('Page not found')
                    // this.router.navigate(['login'])
                } else if(err.status == 500) {
                    this.toastr.warning('Internal server error')
                    // this.router.navigate(['login'])  
                }
            }
        }));
    }
}
