
<div class="row my-3 mx-0 bg-white px-2 py-4">
    <div class="col-md-1 py-4">
        <button class="btn btn-muted">
            <img src="./assets/img/letter-x.png" alt="" srcset="">
        </button>
    </div>
    <div class="col-md-2 py-3">
        <div style="height: 60px; width: 60px; border-radius: 50%;">
            <img class="img-fluid" style="border-radius: 50%;"
            src="./assets/img/boy.png" alt="">
        </div>
    </div>
    <div class="col-md-7">
        <h4>Jennifer</h4>
        <p class="text-muted">
            <span class="text-dark">There are lorem ipsum</span>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
        </p>
    </div>
    <div class="col-md-2">
        <p class="text-muted">
            <img height="20px" class="pr-2" src="./assets/img/clock.png" alt="">
            11:20 am
        </p>
    </div>
</div>

<div class="row my-3 mx-0 bg-white px-2 py-4">
    <div class="col-md-1 py-4">
        <button class="btn btn-muted">
            <img src="./assets/img/letter-x.png" alt="" srcset="">
        </button>
    </div>
    <div class="col-md-2 py-3">
        <div style="height: 60px; width: 60px; border-radius: 50%;">
            <img class="img-fluid" style="border-radius: 50%;"
            src="./assets/img/boy.png" alt="">
        </div>
    </div>
    <div class="col-md-7">
        <h4>Jennifer</h4>
        <p class="text-muted">
            <span class="text-dark">There are lorem ipsum</span>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
        </p>
    </div>
    <div class="col-md-2">
        <p class="text-muted">
            <img height="20px" class="pr-2" src="./assets/img/clock.png" alt="">
            11:20 am
        </p>
    </div>
</div>

<div class="row my-3 mx-0 bg-white px-2 py-4">
    <div class="col-md-1 py-4">
        <button class="btn btn-muted">
            <img src="./assets/img/letter-x.png" alt="" srcset="">
        </button>
    </div>
    <div class="col-md-2 py-3">
        <div style="height: 60px; width: 60px; border-radius: 50%;">
            <img class="img-fluid" style="border-radius: 50%;"
            src="./assets/img/boy.png" alt="">
        </div>
    </div>
    <div class="col-md-7">
        <h4>Jennifer</h4>
        <p class="text-muted">
            <span class="text-dark">There are lorem ipsum</span>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
        </p>
    </div>
    <div class="col-md-2">
        <p class="text-muted">
            <img height="20px" class="pr-2" src="./assets/img/clock.png" alt="">
            11:20 am
        </p>
    </div>
</div>

<div class="row my-3 mx-0 bg-white px-2 py-4">
    <div class="col-md-1 py-4">
        <button class="btn btn-muted">
            <img src="./assets/img/letter-x.png" alt="" srcset="">
        </button>
    </div>
    <div class="col-md-2 py-3">
        <div style="height: 60px; width: 60px; border-radius: 50%;">
            <img class="img-fluid" style="border-radius: 50%;"
            src="./assets/img/boy.png" alt="">
        </div>
    </div>
    <div class="col-md-7">
        <h4>Jennifer</h4>
        <p class="text-muted">
            <span class="text-dark">There are lorem ipsum</span>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
        </p>
    </div>
    <div class="col-md-2">
        <p class="text-muted">
            <img height="20px" class="pr-2" src="./assets/img/clock.png" alt="">
            11:20 am
        </p>
    </div>
</div>

<div class="row my-3 mx-0 bg-white px-2 py-4">
    <div class="col-md-1 py-4">
        <button class="btn btn-muted">
            <img src="./assets/img/letter-x.png" alt="" srcset="">
        </button>
    </div>
    <div class="col-md-2 py-3">
        <div style="height: 60px; width: 60px; border-radius: 50%;">
            <img class="img-fluid" style="border-radius: 50%;"
            src="./assets/img/boy.png" alt="">
        </div>
    </div>
    <div class="col-md-7">
        <h4>Jennifer</h4>
        <p class="text-muted">
            <span class="text-dark">There are lorem ipsum</span>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
        </p>
    </div>
    <div class="col-md-2">
        <p class="text-muted">
            <img height="20px" class="pr-2" src="./assets/img/clock.png" alt="">
            11:20 am
        </p>
    </div>
</div>
