<header id="header-wrap" data-spy="affix" data-offset-top="55" class="header">
    <div class="container">
    <div class="row">
    <div class="col-sm-12">
    <nav class="navbar navbar-default d-block">
    <div class="navbar-header">
    <a class="navbar-brand" href="#">
    <img src="./assets/landing/images/logo.png" alt="logo">
    </a>
    </div>
    </nav>
    </div>
    </div>
    </div>
    </header>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="innerText" [innerHTML]="innerData"></div>
                <h3 class="contact_us" routerLink="/contact">Please feel free to contact us if any of the above doesn't clarify your question.</h3>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
            <div class="footer-copyright text-center iq-ptb-20">Copyright @<span id="copyright"> <script data-cfasync="false" src="/cdn-cgi/scripts/5c5dd728/cloudflare-static/email-decode.min.js"></script><script>document.getElementById('copyright').appendChild(document.createTextNode(new Date().getFullYear()))</script></span> <a href="#" class="text-green">CBC</a> All Rights Reserved </div>
            </div>
        </div>
    </div>


