import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { CredentialService } from './credential.service';
@Injectable({
  providedIn: 'root'
})
export class ChatService {
  baseChatUrl:string = environment.apiChatUrl;
  baseApiUrl:string = environment.apiUrl;


  header = new HttpHeaders({
    'Accept' : 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Headers': 'Content-Type',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': 'true',
    'Access-Control-Allow-Methods': 'POST',
    // 'ApplicationType': 'BROWSER',
  });

  private _chatList = this.baseChatUrl+'/chatList';
  private _chatHistory = this.baseChatUrl+'/chatHistory';
  private _contactList = this.baseApiUrl+'/getList';
  private _contactListMain = this.baseApiUrl+'/contactList';
  
  private _profile = this.baseApiUrl+'/editProfile';
  constructor(private http: HttpClient,
    private _credentials : CredentialService) {}

    getJoinedList(userId, parent_id,token): Observable<HttpResponse<any>> {
      this.header.append("authtoken" , token);
      return this.http.post<any>(this.baseApiUrl+'/getJoinedList', {
        "userId": userId,
      "parent_id": parent_id
        },{
          headers: this.header,
          observe: 'response'
        });
   
  }
  
  getChatList(userId, token): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(this._chatList, {
      userId,
      token
    }, {
      headers: this.header,
      observe: 'response'
    })
  }


  getContactList(userId, token, appType): Observable<HttpResponse<any>> {
   this.header.append("authtoken" , token);
   
    return this.http.post<HttpResponse<any>>(this._contactList, {
      userId,
      appType
    }, {
      headers: this.header,
      observe: 'response'
    })
  }
  getContactListMain(userId, token, appType): Observable<HttpResponse<any>> {
    this.header.append("authtoken" , token);
    
     return this.http.post<HttpResponse<any>>(this._contactListMain, {
       userId,
       appType
     }, {
       headers: this.header,
       observe: 'response'
     })
   }
  Editgroupdetails(grpdetails): Observable<HttpResponse<any>> {
    
     return this.http.post<HttpResponse<any>>(this.baseChatUrl+'/editGroup',grpdetails , {
       headers: this.header,
       observe: 'response'
     })
   }



   Leavegroup(grpdetails): Observable<HttpResponse<any>> {
    
    return this.http.post<HttpResponse<any>>(this.baseChatUrl+'/leaveGroup',grpdetails , {
      headers: this.header,
      observe: 'response'
    })
  }


  getContactListnew(userId, token, appType): Observable<HttpResponse<any>> {
    this.header.append("authtoken" , token);
    
     return this.http.post<HttpResponse<any>>(this._contactList, {
       userId,
       appType
     }, {
       headers: this.header,
       observe: 'response'
     })
   }


  getChatHistory(userId, token, remoteUserId): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(this._chatHistory, {
      userId,
      token,
      remoteUserId,
      chatMode: 'NONSECRET',
      page: 0
    }, {
      headers: this.header,
      observe: 'response'
    })
  }

  fileUpload(filedata){
    
   return this.http.post<any>(this.baseChatUrl+'/fileUpload', filedata);
    
  }


  Getgrouphistory(token,groupid){
    
    return this.http.post<any>(this.baseChatUrl+'/groupChatHistory', {
    "token" : token,
    "chatMode": "NONSECRET",
    "page": 0,
    "groupId":  groupid
    });
     
   }

   getGroupParticipantList(token,groupid){
    
    return this.http.post<any>(this.baseChatUrl+'/groupDetails', {
    "token" : token,
    "groupId":  groupid
    });
     
   }
   

  creategroup(groupdata){
    var headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
       'authtoken': groupdata.token
    });



    return this.http.post<any>(this.baseChatUrl+'/createGroup', groupdata,{
      headers: headers_object,
      observe: 'response'
    });
     
   }


   Addingroup(groupdata){
    
    return this.http.post<any>(this.baseChatUrl+'/addGroup', groupdata,{
      headers: this.header,
      observe: 'response'
    });
     
   }

   updateprofile(userdata, token,authtoken): Observable<HttpResponse<any>> {
    this.header.append("authtoken" , authtoken);
     return this.http.post<HttpResponse<any>>(this._profile, {
      "firstName": userdata.fname,
    "lastName" : userdata.lname,
    "chatToken" : token,
    "userName": userdata.username
     }, {
       headers: this.header,
       observe: 'response'
     })
   }
}
