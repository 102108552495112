
<h2 mat-dialog-title class="color-dd-gradient font-weight-bold">Forgot Password</h2>
<div mat-dialog-content class="mat-typography">
  <div class="p-md-5 mx-0 w-100 bg-white shadow-lg border-20">
      <div class="input-group mb-3 shadow border-40 my-3">
        <input type="email" class="form-control border-40 border-right-0 pl-5"
        style=""
        placeholder="Enter email address" aria-describedby="basic-addon2"
        #forgotPassword>
        <div class="input-group-append" style="height: 67px;">
          <span class="input-group-text border-40 border-left-0 bg-white py-2 pr-3" id="basic-addon2">
              <img height="43px" src="assets/img/user.png" alt="" />
          </span>
        </div>
      </div>
      <div class="row justify-content-center mx-0">
        <button type="button" class="btn btn-theme border-40 btn batton mt-2 text-center"
        (click)="submitForgotPassword()">Forgot Password</button>
      </div>

      <div class="row justify-content-center mx-0">
        <button type="button" class="btn btn-theme border-40 btn batton mt-2 text-center"
        (click)="submitForgotUsername()">Forgot Username</button>
      </div>
  </div>
</div>
<!-- <div mat-dialog-actions align="end">
  <button mat-button (click)="onNoClick()">Close</button>
</div> -->
<style>
  .btn-theme {
	width: auto;
	height: auto;
}
.color-dd-gradient {
  color: #000 !important;
  background-image: none !important;
  background-color: #000 !important;
  font-family: 'Poppins' !important;
  font-weight: 400 !important;
}
.input-group.mb-3.shadow.border-40.my-3 {
	width: auto;
	margin: 0;
}
.input-group-append {
	margin-left: 0;
}
</style>