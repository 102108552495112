
<div class="inner_cont_top h-100">
<div class="row faq_dec">
    <div  class="inner_header">

        <a class="userImg3" data-toggle="tab" href="#">
            <div class="imgbg logo-img">
            <img src="./assets/img/logo.png" alt="">                     
            </div>
        </a>
        <span id="sidebarCollapse" class="fa fa-bars mawn u"></span>

</div>





    <ul class="nav nav-tabs flex-column w-100" id="sidebar">
           
            <!-- <span class="m-0" id="sidebar"> -->
                <li class="nav-item pl-4" routerLinkActive="sidebar-active"
                routerLink="/dashboard/contact">
                    <a class="nav-link" data-toggle="tab">
                        <img height="25px" class="pr-3" src="./assets/img/call-icon.png" alt="">
                        Contacts
                    </a>
                </li>
                <li class="nav-item pl-4" routerLinkActive="sidebar-active"
                routerLink="/dashboard/chat">
                    <a class="nav-link" data-toggle="tab">
                        <img height="25px" class="pr-3" src="./assets/img/chat-bubble.png" alt="">
                        Chat
                    </a>
                </li>
               <li class="nav-item pl-4" routerLinkActive="sidebar-active"
                routerLink="/dashboard/profile">
                    <a class="nav-link" data-toggle="tab">
                        <img height="25px" class="pr-3" src="./assets/img/user-3-fill.png" alt="">
                    Profile
                    </a>
                </li> 
                
                <!-- <li class="nav-item pl-4" routerLinkActive="sidebar-active"
                routerLink="/dashboard/calendar">
                    <a class="nav-link" data-toggle="tab">
                        <img height="25px" class="pr-3" src="./assets/img/calendar.png" alt="">
                        Calendar
                    </a>
                </li> -->
                 <!-- <li class="nav-item pl-4" routerLinkActive="sidebar-active"
                    routerLink="/dashboard/notification">
                    <a class="nav-link" data-toggle="tab">
                        <img height="25px" class="pr-3" src="./assets/img/bell.png" alt="">
                        Notification
                    </a>
                </li>  -->
                <li class="nav-item pl-4" routerLinkActive="sidebar-active"
                    routerLink="/dashboard/contactus">
                    <a class="nav-link" data-toggle="tab">
                        <img  class="" src="./assets/img/dialing-phone.png" style="height: 11px;" alt="">
                        Contact Us
                    </a>
                </li>
                <!-- <li class="nav-item pl-4">
                    <a class="nav-link" data-toggle="tab">
                        <img height="25px" class="pr-3" src="./assets/img/desktop.png" alt="">
                        Desktop Sharing
                    </a>
                </li> -->
                <!--<li class="nav-item pl-4">
                    <a class="nav-link" data-toggle="tab">
                        <img height="25px" class="pr-3" src="./assets/img/question.png" alt="">
                        Help
                    </a>
                </li>-->
                <div class="d-flex w-100 justify-content-center p-5">
                    <img src="./assets/img/three-dots.png" alt="" class="mx-auto">
                </div>
            <!-- </span> -->
    </ul>
</div>

</div>