import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from './auth.service';
import { ToastrService } from 'ngx-toastr';
import { HttpResponse } from '@angular/common/http';
import { WebSocketService } from '../services/web-socket.service';
@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(public auth: AuthService, public router: Router,     private WebSocketService: WebSocketService,
    private toastr: ToastrService) {
     
      if (localStorage['loginonce']===undefined)
      {localStorage.setItem('loginonce','1');
      }
     if(localStorage['loginonce']==='2')
      { 
        this.auth.userLogout()
        .subscribe((response : HttpResponse<any>)=>{
          let res = JSON.parse(JSON.stringify(response));
          if(res.response_code == 2000) {
            this.toastr.success(res.response_message);
            localStorage.removeItem("auth_data");
          
            this.router.navigate(['']);
          } else {
            this.toastr.error(res.response_message);
            this.router.navigate(['']);
          }
        }, error => {
          console.log(error);
          this.toastr.error(error.error.message);
          this.router.navigate(['']);
        });
        localStorage.clear();
        this.WebSocketService.disconnect();
         this.toastr.info('You need to sign in to access this section');
        this.router.navigate(['/']);   
      }   
    }
  canActivate(): boolean {
    //localStorage.setItem('loginonce','2');
    if (!this.auth.isAuthenticated()) {
      this.toastr.info('You need to sign in to access this section');
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }
}