<!-- <header id="header-wrap" data-spy="affix" data-offset-top="55" class="header">
    <div class="container">
    <div class="row">
    <div class="col-sm-12">
    <nav class="navbar navbar-default d-block">
    <div class="navbar-header">
    <a class="navbar-brand" href="#">
    <img src="./assets/landing/images/logo.png" alt="logo">
    </a>
    </div>
    </nav>
    </div>
    </div>
    </div>
    </header> -->
<div class="container mw-100">
    <h4 class="text-center iq-tw-6 iq-pb-20 iq-mb-40 heading">{{dataTitle}}</h4>
    <div class="wrap">
       
        <div class="form_wrap shadow">
            <form [formGroup]="myReactiveForm" (ngSubmit)="onSubmit()">
                <div class="row"  *ngIf="showMsg">
                    <div class="col-xs-12">
                        <p class="alert alert-success" *ngIf="resData!=''">{{resData}}</p>
                    </div>
                  </div>
            <div class="mb-5 section-field">
                <!-- <label for="username" class="form-label">Name</label> -->
                <input type="text" class="form-control" id="username" placeholder="name" formControlName="name">
                <small class="text-danger" *ngIf = "!myReactiveForm.get('name').valid && myReactiveForm.get('name').touched">Please enter Name</small>
                
            </div>
            <div class="mb-5 section-field">
                <!-- <label for="email" class="form-label">Email address</label> -->
                <input type="email" class="form-control" id="email" placeholder="name@example.com" formControlName="email">
                <small class="text-danger" *ngIf = "!myReactiveForm.get('email').valid && myReactiveForm.get('email').touched">Please enter valid email</small>
            </div>
            <div class="mb-5 section-field">
                <!-- <label for="username" class="form-label">Subject</label> -->
                <input type="text" class="form-control" id="subject" placeholder="subject" formControlName="subject">
                <small class="text-danger" *ngIf = "!myReactiveForm.get('subject').valid && myReactiveForm.get('subject').touched">Please enter subject</small>

            </div>
            
            <div class="mb-5 section-field">
                <!-- <label for="textarea" class="form-label">Massage</label> -->
                <textarea class="form-control" id="textarea" rows="3" formControlName="msg" placeholder="message"></textarea>
                <small class="text-danger" *ngIf = "!myReactiveForm.get('msg').valid && myReactiveForm.get('msg').touched">Please enter msg</small>

            </div>
            <button type="submit"  class="button iq-mt-30 btn_submit">Submit</button>
            
            </form>
        </div>
    
    </div>
</div>
<div class="container">
    <div class="row">
        <div class="col-md-12">
        <div class="footer-copyright text-center iq-ptb-20">Copyright @<span id="copyright"> <script data-cfasync="false" src="/cdn-cgi/scripts/5c5dd728/cloudflare-static/email-decode.min.js"></script><script>document.getElementById('copyright').appendChild(document.createTextNode(new Date().getFullYear()))</script></span> <a href="#" class="text-green">CBC</a> All Rights Reserved </div>
        </div>
    </div>
</div>
