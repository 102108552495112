
<div class="container mw-100">
    <h4 class="text-center iq-tw-6 iq-pb-20 iq-mb-40 heading">{{dataTitle}}</h4>
    <div class="wrap">
       
        <div class="form_wrap shadow">
            <form [formGroup]="myReactiveForm" (ngSubmit)="onSubmit()">
                <div class="row"  *ngIf="showMsg">
                    <div class="col-xs-12">
                        <p class="alert alert-success" *ngIf="resData!=''">{{resData}}</p>
                    </div>
                  </div>
            <div class="mb-5 section-field">
                <!-- <label for="username" class="form-label">Name</label> -->
                <input type="text" class="form-control" id="username" placeholder="Mobile No" formControlName="mobile" disabled>
                
            </div>
            <div class="mb-5 section-field">
                <!-- <label for="email" class="form-label">Email address</label> -->
                <input type="email" class="form-control" id="email" placeholder="name@example.com" formControlName="email" disabled>
                <small class="text-danger" *ngIf = "!myReactiveForm.get('email').valid && myReactiveForm.get('email').touched">Please enter valid email</small>
            </div>
            <div class="mb-5 section-field">
                <!-- <label for="username" class="form-label">Subject</label> -->
                <input type="text" class="form-control" id="fname" placeholder="First Name" formControlName="fname">
                <small class="text-danger" *ngIf = "!myReactiveForm.get('fname').valid && myReactiveForm.get('fname').touched">Please enter First Name</small>

            </div>
            
            <div class="mb-5 section-field">
                <input type="text" class="form-control" id="lname" placeholder="Last Name" formControlName="lname">
                <small class="text-danger" *ngIf = "!myReactiveForm.get('lname').valid && myReactiveForm.get('lname').touched">Please enter Last Name</small>
               
            </div>

            <div class="mb-5 section-field">
                <input type="text" class="form-control" id="fname" placeholder="User Name" formControlName="username" disabled>
               
            </div>
            <button type="submit"  class="button iq-mt-30 btn_submit">Update</button>
            
            </form>
        </div>
    
    </div>
</div>
<div class="container">
    <div class="row">
        <div class="col-md-12">
        <div class="footer-copyright text-center iq-ptb-20">Copyright @<span id="copyright"> <script data-cfasync="false" src="/cdn-cgi/scripts/5c5dd728/cloudflare-static/email-decode.min.js"></script><script>document.getElementById('copyright').appendChild(document.createTextNode(new Date().getFullYear()))</script></span> <a href="#" class="text-green">CBC</a> All Rights Reserved </div>
        </div>
    </div>
</div>
