import { HttpResponse } from '@angular/common/http';
import { Component, OnInit,HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { CredentialService } from 'src/app/services/credential.service';
import { WebSocketService } from '../../../services/web-socket.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  userName: string;
  userImage: string;
  dropdownOpen: boolean = false;

  constructor(
      private _auth: AuthService,
      private toastr: ToastrService,
      private router: Router,
      private _credentials: CredentialService,
      private WebSocketService: WebSocketService,
    ) { }

  ngOnInit(): void {

    this.userName = this._credentials.credentials.firstName+' '+this._credentials.credentials.lastName
    this.userImage = this._credentials.credentials.profileImage;

    
  }
 
  userLogout() {
   
    this._auth.userLogout()
    .subscribe((response : HttpResponse<any>)=>{
      let res = JSON.parse(JSON.stringify(response));
      if(res.response_code == 2000) {
        this.toastr.success(res.response_message);
        localStorage.removeItem("auth_data");
      
        this.router.navigate(['']);
      } else {
        this.toastr.error(res.response_message);
        this.router.navigate(['']);
      }
    }, error => {
      console.log(error);
      this.toastr.error(error.error.message);
      this.router.navigate(['']);
    });
    localStorage.clear();
    this.WebSocketService.disconnect();
  }
  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
 }


}
