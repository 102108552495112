import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ChatService } from 'src/app/services/chat.service';
import { CredentialService } from 'src/app/services/credential.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class profileComponent implements OnInit {

  myReactiveForm: FormGroup;
  userData: any = {};
  dataTitle: string = "User Profile";
  resData: any = "";
  showMsg: boolean = false;

  constructor(      private router: Router,private _credential: CredentialService,private _myUser: ChatService) { }

  ngOnInit(): void {

  var logindata=  JSON.parse(localStorage["auth_data"]);
    this.myReactiveForm = new FormGroup({
      'mobile': new FormControl(logindata.phone, [Validators.required]),
      'email': new FormControl(logindata.email, [Validators.required, Validators.email]),
      'fname': new FormControl(logindata.firstName,[Validators.required]),
      'lname': new FormControl(logindata.lastName,[Validators.required]),
      'username': new FormControl(logindata.userName,[Validators.required]),
    })
  }
  onSubmit() {
    ;
    if (this.myReactiveForm.valid) {
      this.userData = this.myReactiveForm.value;
      this._myUser.updateprofile(this.userData,this._credential.credentials.chatToken,this._credential.credentials.authToken).subscribe((response_data) => {
        this.resData = response_data.body.response_message;
        this.showMsg = true
        ;
        var logindata=  JSON.parse(localStorage["auth_data"]);


        logindata.firstName=this.userData.fname;
        logindata.lastName=this.userData.lname;
        localStorage.setItem("auth_data", JSON.stringify(logindata));
     
        this._credential.setCredentials(logindata);

        window.location.reload();
      })
      
     
     
    } else {
      let key = Object.keys(this.myReactiveForm.controls)
      key.filter(data => {
        let control = this.myReactiveForm.controls[data];
        if (control.errors != null) {
          control.markAsTouched();
        }
      })

    }

  }

}
