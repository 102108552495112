<div class="container-fluid">
    <div class="row">
        <div class="header d-flex w-100 justify-content-end">
            <ul class="list-group list-group-horizontal mr-4 mb-0">
                <li class="list-group-item p-0 d-flex align-items-center c_pointer">
                    <a class="nav-link py-0">
                        <!-- <img height="25px" src="./assets/img/edit.png" alt=""> -->
                    </a>
                </li>
                <li class="list-group-item p-0 d-flex align-items-center c_pointer">
                    <a class="nav-link py-0 pr-4 border-right">
                        <!-- <img height="25px" src="./assets/img/magnifying-glass.png" alt=""> -->
                    </a>
                </li>
                <!-- Dropdown -->
                <li class="list-group-item dropdown ml-2">
                    <a class="nav-link d-flex align-items-center dropdown-toggle c_pointer"
                        id="navbardrop" data-toggle="dropdown" (click)="toggleDropdown()">
                        <div class="logged_user">
                            <img *ngIf="userImage==''"
                            src="./assets/img/boy.png" alt="">
                            <img *ngIf="userImage!=''"
                            src="{{userImage}}" alt="">
                        </div>
                        {{userName}}
                    </a>
                    <div class="dropdown" [ngClass]="{'show': dropdownOpen}" (click)="toggleDropdown()">
                       
                        <div class="dropdown-menu" [ngClass]="{'show': dropdownOpen}">
                            <a class="dropdown-item border-0" (click)="userLogout()">
                                <span class="material-icons li-icon text-dark">
                                    double_arrow
                                </span>
                                LOG OUT
                            </a>
                        </div>
                    </div>
                    
                    <!-- <span id="sidebarCollapse" class="fa fa-bars mawn u"></span> -->
                </li>
            </ul>        
        </div>
    </div>
</div>