<img class="h-100 w-100 position-absolute dialing-bg"
    src="./assets/img/GettyImages-1092658864_hero-1024x575.jpg" alt="">
<div class="dialing">
    <h3 class="text-white text-center py-5">Group Video Calling</h3>
    <div class="ripple-background" style="height: 450px !important">
        <div class="rounded-circle border" 
        style="height: 300px; width: 300px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);">
            <div class="circle-11 small-1" 
            style="height: 70px; width: 70px; top: 5%;">
                <img class="h-100 w-100 position-absolute rounded-circle" 
                src="./assets/img/download.jpeg" alt="">
            </div>
            <div class="circle-11 small-1" 
            style="height: 70px; width: 70px; top: 95%;">
                <img class="h-100 w-100 position-absolute rounded-circle" 
                src="./assets/img/boy.png" alt="">
            </div>
            <div class="circle-11 small-1" 
            style="height: 70px; width: 70px; left: 5%;">
                <img class="h-100 w-100 position-absolute rounded-circle" 
                src="./assets/img/download.jpeg" alt="">
            </div>
            <div class="circle-11 small-1" 
            style="height: 70px; width: 70px; left: 95%;">
                <img class="h-100 w-100 position-absolute rounded-circle" 
                src="./assets/img/boy.png" alt="">
            </div>
            <div class="rounded-circle border" 
            style="height: 250px; width: 250px; margin: 25px;">
                <div class="rounded-circle border" 
                style="height: 150px; width: 150px; margin: 50px;"></div>
            </div>
        </div>
        <div class="circle-12 small-1" style="height: 70px; width: 70px">
            <img class="h-100 w-100 position-absolute rounded-circle" 
            src="./assets/img/GettyImages-1092658864_hero-1024x575.jpg" alt="">
        </div>
    </div>
    <div class="row mx-0 d-flex align-items-center">
        <div class="mx-auto">
            <button class="btn btn-outline-white btn-gp border-0 mx-2 px-0">
                <img height="20px" 
                    src="./assets/img/dialing-microphone.png" alt="">
            </button>
            <button class="btn btn-outline-white btn-gp border-0 mx-2 px-0">
                <img height="20px" 
                    src="./assets/img/dialing-speaker.png" alt="">
            </button>
            <button class="btn btn-danger btn-gp border-0 mx-2">
                <img height="10px" 
                    src="./assets/img/dialing-phone.png" alt="">
            </button>
            <button class="btn btn-outline-white btn-gp border-0 mx-2 px-0">
                <img height="20px" 
                    src="./assets/img/dialing-add.png" alt="">
            </button>
            <button class="btn btn-outline-white btn-gp border-0 mx-2 px-0">
                <img height="20px" 
                    src="./assets/img/dialing-video.png" alt="">
            </button>
        </div>
    </div>
</div>