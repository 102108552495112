
<h2 mat-dialog-title class="color-dd-gradient font-weight-bold">Change Password</h2>
<div mat-dialog-content class="mat-typography">
  <div class="p-md-5 mx-0 w-100 bg-white shadow-lg border-20">
    <div class="input-group mb-3 shadow border-40 my-4">
      <input type="password" class="form-control border-40 border-right-0 pl-5" 
      style="width: 80%;"
      placeholder="Enter current password" aria-describedby="basic-addon2"
      #currentPassword>
      <div class="input-group-append" style="height: 67px;">
        <span class="input-group-text border-40 border-left-0 bg-white pr-4" id="basic-addon2">
            <img height="43px" src="assets/img/lock.png" alt="" />
        </span>
      </div>
    </div>
    <div class="input-group mb-3 shadow border-40 my-4">
      <input type="password" class="form-control border-40 border-right-0 pl-5" 
      style="width: 80%;"
      placeholder="Enter new password" aria-describedby="basic-addon2"
      #newPassword>
      <div class="input-group-append" style="height: 67px;">
        <span class="input-group-text border-40 border-left-0 bg-white pr-4" id="basic-addon2">
            <img height="43px" src="assets/img/lock.png" alt="" />
        </span>
      </div>
    </div>
    <div class="row justify-content-center mx-0">
      <button type="button" class="btn btn-theme border-40"
      (click)="submitChangePassword()">Submit</button>
    </div>
  </div>
</div>
<!-- <div mat-dialog-actions align="end">
  <button mat-button (click)="onNoClick()">Close</button>
</div> -->
