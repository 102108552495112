import { Injectable } from '@angular/core';

export interface Utils {
  // Customize received credentials here
 
}
@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  private _utils: Utils | null = null;
  constructor() {}

  
  dynamicSort(property) {
    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
        /* next line works with strings and numbers, 
         * and you may want to customize it to your needs
         */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}
}
