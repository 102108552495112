import { HttpResponse } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'terms-service',
  templateUrl: './termservice.component.html',
  styleUrls: ['./termservice.component.scss']
})
export class TermserviceComponent implements OnInit {


  constructor() {

  }

  ngOnInit(): void {
  }




}
