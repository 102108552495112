<div class="row mx-0 h-100 chatcontact">
  <div class="col-md-4 px-0 bg-white" [ngClass]="chatSection">
    <div
      class="
        w-100
        py-4
        m-0
        theme-head
        px-3
        d-flex
        align-items-center
        border-right border-bottom
        bg-white
        height-rs
      "
    >
      <div class="text-right contacts-grp">
        <img
          class="mx-3"
          style="filter: contrast(0.2)"
          height="30px"
          src="./assets/img/chat-bubble.png"
          alt=""
        />
        Contacts
      </div>
      <div style="text-align: right"  (click)="addongroup()" class="contacts-grp">
        <img
          class="m-3"
          style="filter: contrast(0.2)"
          height="20px"
          src="./assets/img/contact-group.png"
          alt=""
         
        />
        Create Group
      </div>
    </div>

    <div class="contact-screen-top">
      <div class="contact-screen" style="max-height: 530px; overflow-y: scroll">
        <div class="search_box px-3">
          <input
            class="form-control h-auto mb-3"
            #myInput
            placeholder="Search Here..."
            [(ngModel)]="nameSearch"
            (input)="filterItem(myInput.value)"
          />
        </div>
        <div class="container w-100">
          <div
         
            *ngFor="let contact of contactList; let indexOfelement = index"
          >
          <div style="background-color: #fff;" id="{{contact.RemoteUserOrGroupId}}"  class="row  border-bottom py-2"  (click)="
          changeclass(
                      contact.RemoteUserOrGroupId,
                      contact.RemoteUserDisplayOrGroupName,
                      contact.RemoteUserOrGroupImage
                    )
        ">
            <div class="col-1">
              {{ indexOfelement + 1 }}
            </div>
            <div class="rounded-circle" [ngClass]="{'placeholder-bg': !contact.profileImg}" style="width: 30px; height: 30px; display: flex; align-items: center; justify-content: center; overflow: hidden; font-size: 16px; color: white; background-color: grey;">
              <ng-container *ngIf="contact.profileImg; else placeholderText">
                <img
                  class="rounded-circle"
                  style="width: 30px; height: 30px; object-fit: cover"
                  [src]="contact.profileImg"
                  alt="Profile"
                />
              </ng-container>
              <ng-template #placeholderText>
                <span style="font-weight: bold">{{ contact.RemoteUserFirstName.charAt(0) }}</span>
              </ng-template>
            </div>
            
          
            <div class="col-6">
              {{ contact.RemoteUserFirstName }}({{ contact.phone }})
            </div>
            <div
              [ngStyle]="{
                visibility: contact.Issync === true ? 'visible' : 'hidden'
              }"
            >
              <div class="d-flex align-items-center">
                <div class="" (click)="intializeVideoCall(contact)">
                  <i class="fa fa-video-camera"></i>
                </div>
                <div class="mx-3" (click)="intializeAudioCall(contact)">
                  <i class="fa fa-phone"></i>
                </div>
                <div
                  class=""
                  (click)="
                    getChatHistory(
                      contact.RemoteUserOrGroupId,
                      contact.RemoteUserDisplayOrGroupName,
                      contact.RemoteUserOrGroupImage
                    )
                  "
                >
                  <i class="fa fa-commenting-o"></i>
                </div>
              </div>
            </div>
            </div>
          </div>
          <div *ngIf="contactList.length == 0">No Contacts To Display</div>
        </div>
      </div>
    </div>
    <!-- <div class="row mx-0 px-2 position-absolute" style="bottom: 0px;">
      <img
        height="25px"
        class="pr-2"
        src="./assets/img/contact-group.png"
        alt=""
      />
      <p class="text-muted">Create a new contact group</p>
    </div> -->
  </div>
  <div class="col-md-8 px-0">
    <div *ngIf="isChatItemClicked">
      <div class="row mx-0 bg-white border-bottom px-3 mb-0 height-rs">
        <div class="col-md-2 px-0 py-3">
          <div style="height: 40px; width: 40px; border-radius: 50%">
            <!-- <img
              class="rounded-circle"
              style="height: 40px; width: 40px;object-fit: cover;"
              *ngIf="userImage == ''"
              src="./assets/img/boy.png"
              alt=""
            />
            <img
              class="rounded-circle"
              style="height: 40px; width: 40px;object-fit: cover;"
              *ngIf="userImage != ''"
              src="{{ userImage }}"
              alt=""
            /> -->
          </div>
        </div>
        <div class="col-md-4 px-0 d-flex align-items-center">
          <div class="ml-0 w-100">
            <h4 class="text-truncate" title="{{ userName }}">{{ userName }}</h4>
            <p class="text-muted" style="font-family: sans-serif">
              <!-- <img src="./assets/img/skype.png"
                            alt="" class="mr-2"> -->
              Active Now
            </p>
          </div>
        </div>
        <div
          class="col-md-6 px-0 d-flex align-items-center justify-content-end"
        >
          <button
            class="btn mx-2 rounded-circle btn-action"
            style="display: none"
          >
            <img height="20px" src="./assets/img/video-call.png" alt="" />
          </button>
          <button
            class="btn mx-2 rounded-circle btn-action"
            style="display: none"
          >
            <img height="20px" src="./assets/img/phone.png" alt="" />
          </button>
          <button
            class="btn mx-2 rounded-circle btn-action"
            style="display: none"
          >
            <img height="20px" src="./assets/img/power-button.png" alt="" />
          </button>
          <button
            class="btn mx-2 rounded-circle btn-action"
            style="display: none"
          >
            <img height="20px" src="./assets/img/magnifying-glass.png" alt="" />
          </button>
        </div>
      </div>
      <div>
        <!-- Nav tabs -->
        <ul class="nav nav-tabs bg-inherit border-bottom">
          <li class="nav-item py-3">
            <a
              class="nav-link p-0 bg-inherit text-muted border-0 active"
              data-toggle="tab"
              href="#conversation"
              >Conversation</a
            >
          </li>
          <li class="nav-item py-3">
            <a
              class="nav-link p-0 bg-inherit text-muted border-0"
              data-toggle="tab"
              href="#files"
              >Files</a
            >
          </li>
          <li class="nav-item py-3 d-none">
            <a
              class="nav-link p-0 bg-inherit text-muted border-0"
              data-toggle="tab"
              href="#organization"
              >Organization</a
            >
          </li>
          <li class="nav-item py-3 d-none">
            <a
              class="nav-link p-0 bg-inherit text-muted border-0"
              data-toggle="tab"
              href="#activity"
              >Activity</a
            >
          </li>
          <img
            class="m-3"
            style="filter: contrast(0.2); display: none"
            height="20px"
            src="./assets/img/plus.png"
            alt=""
          />
        </ul>

        <!-- Tab panes -->
        <div class="tab-content">
          <div
            class="tab-pane container w-100 active"
            id="conversation"
            #scrollMe
            [scrollTop]="scrollMe.scrollHeight"
            style="
              min-height: 300px;
              overflow-y: scroll;
              max-height: calc(100vh - 360px);
              margin-bottom: 150px;
            "
          >
            <div *ngFor="let chat of chatHistory">
              <div
                class="row py-3"
                style="align-items: flex-end"
                *ngIf="chat.userId != loggedInUserId"
              >
                <div class="col-2 px-0">
                  <div
                    class="ml-auto mr-2 rounded-circle"
                    style="height: 30px; width: 30px"
                  >
                    <img
                      class="rounded-circle"
                      style="height: 30px; width: 30px; object-fit: cover"
                      *ngIf="userImage == ''"
                      src="./assets/img/boy.png"
                      alt=""
                    />
                    <img
                      class="rounded-circle"
                      style="height: 30px; width: 30px; object-fit: cover"
                      *ngIf="userImage != ''"
                      src="{{ userImage }}"
                      alt=""
                    />
                  </div>
                </div>
                <div class="col-10 px-0">
                  <div
                    class="bg-white ml-0"
                    style="
                      border-radius: 50px 50px 50px 0;
                      padding: 4px 30px;
                      width: 300px;
                    "
                  >
                    <div class="d-flex">
                      <!-- <h5 class="ml-0 mb-0">{{ userName }}</h5> -->
                      <p
                        class="text-muted small mb-0"
                        style="padding-left: 10px; font-family: sans-serif"
                      >
                        {{ chat.chattime | date: "shortTime" }}
                      </p>
                    </div>
                    <span
                      *ngIf="chat.chatType === 'text'"
                      style="overflow-wrap: anywhere"
                      ><a
                        href="{{ chat.chatText }}"
                        style="color: black"
                        target="_blank"
                        >{{ chat.chatText }}</a
                      ></span
                    >
                    <span
                      *ngIf="
                        chat.type === 'text' && chat.chatText === undefined
                      "
                      style="overflow-wrap: anywhere"
                    >
                      {{ chat.chat }}</span
                    >

                    <span
                      *ngIf="chat.chatType === 'image' || chat.type === 'image'"
                    >
                      <img
                        class="h-25 w-100 zoom"
                        src="{{ chat.attachment }}"
                        alt=""
                      />
                    </span>
                    <span
                      *ngIf="chat.chatType === 'audio' || chat.type === 'audio'"
                    >
                      <audio [ngStyle]="{ width: '255px' }" controls>
                        <source [src]="chat.attachment" />
                      </audio>

                      <!-- <audio class="h-25 w-100" src="{{chat.attachment}}"></audio> -->
                    </span>
                    <span
                      *ngIf="chat.chatType === 'video' || chat.type === 'video'"
                    >
                      <video width="200" height="150" controls>
                        <source [src]="chat.attachment" />
                      </video>
                      <!-- <video class="h-25 w-100" src="{{chat.attachment}}"></video> -->
                    </span>
                    <!-- {{ chat.chatType }} -->
                    <span
                      *ngIf="chat.chatType === 'doc' || chat.type === 'doc'"
                    >
                      <a
                        href="{{ chat.attachment }}"
                        target="__blank"
                        style="color: white; text-decoration: underline"
                        >Open Document</a
                      >

                      <!-- <embed class="h-25 w-100" src="http://www.africau.edu/images/default/sample.pdf"> -->
                    </span>
                  </div>
                </div>
              </div>
              <div
                class="row py-3"
                style="align-items: flex-end"
                *ngIf="chat.userId === loggedInUserId"
              >
                <div class="col-10 px-0">
                  <div
                    class="bg-theme ml-auto mr-2 text-white"
                    style="
                      border-radius: 50px 50px 0 50px;
                      padding: 4px 30px;
                      width: 300px;
                    "
                  >
                    <div class="d-flex">
                      <!-- <h5 class="ml-0 text-white mb-0">{{ loggedInUserName }}</h5> -->
                      <p
                        class="text-black mb-0 small"
                        style="
                          padding-left: 10px;
                          font-family: sans-serif;
                          color: black;
                        "
                      >
                        {{ chat.chattime | date: "shortTime" }}
                      </p>
                    </div>

                    <spa
                      *ngIf="chat.chatType === 'text'"
                      style="overflow-wrap: anywhere"
                      ><a
                        href="{{ chat.chatText }}"
                        style="color: black"
                        target="_blank"
                        >{{ chat.chatText }}</a
                      >
                    </spa>
                    <span
                      *ngIf="
                        chat.chatType === 'text' && chat.chatText === undefined
                      "
                      style="overflow-wrap: anywhere"
                    >
                      {{ chat.chat }}</span
                    >
                    <span
                      *ngIf="chat.chatType === 'image' || chat.type === 'image'"
                    >
                      <img
                        class="h-25 w-100 zoom"
                        src="{{ chat.attachment }}"
                        alt=""
                      />
                    </span>
                    <span
                      *ngIf="chat.chatType === 'audio' || chat.type === 'audio'"
                    >
                      <audio [ngStyle]="{ width: '255px' }" controls>
                        <source [src]="chat.attachment" />
                      </audio>

                      <!-- <audio class="h-25 w-100" src="{{chat.attachment}}"></audio> -->
                    </span>
                    <span
                      *ngIf="chat.chatType === 'video' || chat.type === 'video'"
                    >
                      <video width="200" height="150" controls>
                        <source [src]="chat.attachment" />
                      </video>
                      <!-- <video class="h-25 w-100" src="{{chat.attachment}}"></video> -->
                    </span>

                    <span
                      *ngIf="chat.chatType === 'doc' || chat.type === 'video'"
                    >
                      <a
                        href="{{ chat.attachment }}"
                        target="__blank"
                        style="color: white; text-decoration: underline"
                        >Open Document</a
                      >
                      <!-- <embed class="h-25 w-100" [src]="chat.attachment"> -->
                    </span>
                  </div>
                </div>

                <div class="col-2 px-0">
                  <div
                    class="ml-0 rounded-circle"
                    style="height: 30px; width: 30px"
                  >
                    <img
                      class="rounded-circle"
                      style="height: 30px; width: 30px; object-fit: cover"
                      *ngIf="loggedInUserImage == ''"
                      src="./assets/img/boy.png"
                      alt=""
                    />
                    <img
                      class="rounded-circle"
                      style="height: 30px; width: 30px; object-fit: cover"
                      *ngIf="loggedInUserImage != ''"
                      src="{{ loggedInUserImage }}"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="row py-3" style="align-items: flex-end;">
                            <div class="col-2 px-0">
                                <div class="ml-auto mr-2" style="height: 60px; width: 60px; border-radius: 50%;">
                                    <img class="img-fluid" style="border-radius: 50%;"
                                    src="./assets/img/boy.png" alt="">
                                </div>
                            </div>
                            <div class="col-10 px-0">
                                <div class="bg-white ml-0" style="border-radius: 50px 50px 50px 0;padding: 10px 30px;width: 300px;">
                                    <div class="d-flex">
                                        <h5 class="ml-0">Jennifer</h5>
                                        <p class="text-muted" style="font-family: sans-serif;">11:00 am</p>
                                    </div>
                                    Lorem ipsum dolor sit amet?
                                </div>
                            </div>
                        </div>
                        <div class="row py-3" style="align-items: flex-end;">
                            <div class="col-10 px-0">
                                <div class="bg-theme ml-auto mr-2 text-white" style="border-radius: 50px 50px 0 50px;padding: 10px 30px;width: 300px;">
                                    <div class="d-flex">
                                        <h5 class="ml-0 text-white">Jennifer</h5>
                                        <p class="text-white" style="font-family: sans-serif;">11:00 am</p>
                                    </div>
                                    Lorem ipsum dolor sit amet.
                                </div>
                            </div>
                            <div class="col-2 px-0">
                                <div class="ml-0" style="height: 60px; width: 60px; border-radius: 50%;">
                                    <img class="img-fluid" style="border-radius: 50%;"
                                    src="./assets/img/boy.png" alt="">
                                </div>
                            </div>
                        </div> -->
          </div>
          <div
            class="tab-pane container w-100"
            id="files"
            #scrollMe
            [scrollTop]="scrollMe.scrollHeight"
            style="
              min-height: 300px;
              overflow-y: scroll;
              max-height: calc(100vh - 260px);
              margin-bottom: 100px;
            "
          >
            Files
            <div *ngFor="let chat of chatHistory">
              <div
                class="row py-3"
                style="align-items: flex-end"
                *ngIf="
                  chat.userId != loggedInUserId && chat.chatType !== 'text'
                "
              >
                <div class="col-2 px-0">
                  <div
                    class="ml-auto mr-2 rounded-circle"
                    style="height: 30px; width: 30px"
                  >
                    <img
                      class="rounded-circle"
                      style="height: 30px; width: 30px; object-fit: cover"
                      *ngIf="userImage == ''"
                      src="./assets/img/boy.png"
                      alt=""
                    />
                    <img
                      class="rounded-circle"
                      style="height: 30px; width: 30px; object-fit: cover"
                      *ngIf="userImage != ''"
                      src="{{ userImage }}"
                      alt=""
                    />
                  </div>
                </div>
                <div class="col-10 px-0">
                  <div
                    class="bg-white ml-0"
                    style="
                      border-radius: 50px 50px 50px 0;
                      padding: 4px 30px;
                      width: 300px;
                    "
                  >
                    <div class="d-flex mb-0">
                      <h5 class="ml-0">
                        <!-- {{ userName }} -->

                        {{ chat.sender_username }}
                      </h5>
                      <p
                        class="text-muted small mb-0"
                        style="padding-left: 10px; font-family: sans-serif"
                      >
                        {{ chat.chattime | date: "shortTime" }}
                      </p>
                    </div>
                    <span
                      *ngIf="
                        chat.chatType === 'text' || chat.messageType === 'text'
                      "
                      style="overflow-wrap: anywhere"
                      >{{ chat.chat }}</span
                    >

                    <span
                      *ngIf="
                        chat.chatType === 'image' ||
                        chat.messageType === 'image' ||
                        chat.type === 'image'
                      "
                    >
                      <img
                        class="h-25 w-100 zoom"
                        src="{{ chat.attachment }}"
                        alt=""
                      />
                    </span>
                    <span
                      *ngIf="
                        chat.chatType === 'audio' ||
                        chat.messageType === 'audio' ||
                        chat.type === 'audio'
                      "
                    >
                      <audio [ngStyle]="{ width: '255px' }" controls>
                        <source [src]="chat.attachment" />
                      </audio>

                      <!-- <audio class="h-25 w-100" src="{{chat.attachment}}"></audio> -->
                    </span>
                    <span
                      *ngIf="
                        chat.chatType === 'video' ||
                        chat.messageType === 'video' ||
                        chat.type === 'video'
                      "
                    >
                      <video width="200" height="150" controls>
                        <source [src]="chat.attachment" />
                      </video>
                      <!-- <video class="h-25 w-100" src="{{chat.attachment}}"></video> -->
                    </span>

                    <span
                      *ngIf="
                        chat.chatType === 'doc' ||
                        chat.messageType === 'doc' ||
                        chat.type === 'doc'
                      "
                    >
                      <a
                        href="{{ chat.attachment }}"
                        target="__blank"
                        style="color: white; text-decoration: underline"
                        >Open Document</a
                      >

                      <!-- <embed class="h-25 w-100" src="http://www.africau.edu/images/default/sample.pdf"> -->
                    </span>
                  </div>
                </div>
              </div>
              <div
                class="row py-3"
                style="align-items: flex-end"
                *ngIf="
                  chat.userId === loggedInUserId &&
                  chat.chatType !== 'text' &&
                  chat.messageType !== 'text'
                "
              >
                <div class="col-10 px-0">
                  <div
                    class="bg-theme ml-auto mr-2 text-white"
                    style="
                      border-radius: 50px 50px 0 50px;
                      padding: 4px 30px;
                      width: 300px;
                    "
                  >
                    <div class="d-flex">
                      <h5 class="ml-0 text-white mb-0">
                        {{ loggedInUserName }}
                      </h5>
                      <p
                        class="text-white mb-0 small"
                        style="padding-left: 10px; font-family: sans-serif"
                      >
                        {{ chat.chattime | date: "shortTime" }}
                      </p>
                    </div>

                    <span
                      *ngIf="
                        chat.chatType === 'text' || chat.messageType === 'text'
                      "
                      style="overflow-wrap: anywhere"
                      >{{ chat.chat }}</span
                    >

                    <span
                      *ngIf="
                        chat.chatType === 'image' ||
                        chat.messageType === 'image' ||
                        chat.type === 'image'
                      "
                    >
                      <img
                        class="h-25 w-100 zoom"
                        src="{{ chat.attachment }}"
                        alt=""
                      />
                    </span>
                    <span
                      *ngIf="
                        chat.chatType === 'audio' ||
                        chat.messageType === 'audio' ||
                        chat.type === 'audio'
                      "
                    >
                      <audio [ngStyle]="{ width: '255px' }" controls>
                        <source [src]="chat.attachment" />
                      </audio>

                      <!-- <audio class="h-25 w-100" src="{{chat.attachment}}"></audio> -->
                    </span>
                    <span
                      *ngIf="
                        chat.chatType === 'video' ||
                        chat.messageType === 'video' ||
                        chat.type === 'video'
                      "
                    >
                      <video width="200" height="150" controls>
                        <source [src]="chat.attachment" />
                      </video>
                      <!-- <video class="h-25 w-100" src="{{chat.attachment}}"></video> -->
                    </span>

                    <span
                      *ngIf="
                        chat.chatType === 'doc' ||
                        chat.messageType === 'doc' ||
                        chat.type === 'doc'
                      "
                    >
                      <a
                        href="{{ chat.attachment }}"
                        target="__blank"
                        style="color: white; text-decoration: underline"
                        >Open Document</a
                      >
                      <!-- <embed class="h-25 w-100" [src]="chat.attachment"> -->
                    </span>
                  </div>
                </div>

                <div class="col-2 px-0">
                  <div
                    class="ml-0 rounded-circle"
                    style="height: 30px; width: 30px"
                  >
                    <img
                      class="rounded-circle"
                      style="height: 30px; width: 30px; object-fit: cover"
                      *ngIf="loggedInUserImage == ''"
                      src="./assets/img/boy.png"
                      alt=""
                    />
                    <img
                      class="rounded-circle"
                      style="height: 30px; width: 30px; object-fit: cover"
                      *ngIf="loggedInUserImage != ''"
                      src="{{ loggedInUserImage }}"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane container w-100 h-100 fade" id="organization">
            Organization
          </div>
          <div class="tab-pane container w-100 h-100 fade" id="activity">
            Activity
          </div>
        </div>
      </div>
      <div class="row mx-0 px-5 position-absolute w-100" style="bottom: 10px">
        <div
          class="bg-white w-100 d-block"
          style="border-radius: 20px 0 0 20px"
        >
          <div class="row mx-0 border-bottom">
            <div class="col-10">
              <input
                (keyup.enter)="sendChat()"
                type="text"
                class="form-control border-0 shadow-none h-100"
                placeholder="Type a new message..."
                #message
              />
            </div>
            <div class="col-2">
              <button class="btn w-100 h-100 border-left" (click)="sendChat()">
                <img src="./assets/img/send.png" alt="" />
              </button>
            </div>
          </div>
          <!--chat container-->
          <!--<div class="chat">
                        <div class="">
                            <div class="chat-content">
                                <div class="card">
                                    <ul class="list.group list.group.flush">
                                        <li class="list.group.item" *ngFor="let chatMessage of this.messageValue">
                                            <strong></strong>
                                            <span></span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <form (ngSubmit)="sendMessage(sendForm)" #sendForm="ngForm">
                        <div class="chat-send row">
                            <div class="col-2">
                                <input type="text" name="user" class="form-control" id="input-user" ngModel>
                            </div>
                            <div class="col-8">
                                <input type="text" name="message" class="form-control" id="input-message" ngModel>
                            </div>
                            <div class="col-2">
                                <button type="submit" class="btn btn-primary">Send</button>
                            </div>
                        </div>
                    </form>-->

          <!--end chat-->
          <div class="bg-light" style="height: 20px"></div>
          <div class="px-5 py-3 d-flex">
            <div class="icon-text">
              <!-- <span><img height="20px" class="mx-2" src="./assets/img/type.png" alt=""></span> -->
              <span
                ><label for="file" >
                <img
                  height="20px"
                  class="mx-2"
                  src="./assets/img/clip.png"
                  alt="" />
                </label> <input type="file" id="file" (change)="handleFileInput($event)"
              /></span>

              <!-- <emoji-mart title="Pick your emoji…" emoji="point_up"></emoji-mart>   -->
              <emoji-mart
                (emojiSelect)="addEmoji($event)"
                *ngIf="showEmojiPicker"
                title="Pick your emoji…"
              ></emoji-mart>
              <span
                ><img
                  height="20px"
                  class="mx-2"
                  src="./assets/img/smile.png"
                  (click)="toggleEmojiPicker()"
                  alt=""
              /></span>
              <!-- <span><img height="20px" class="mx-2" src="./assets/img/document-with-paper-clip.png" alt=""></span> -->
              <span
                ><img
                  height="20px"
                  class="mx-2"
                  src="./assets/img/add.png"
                  alt=""
                  style="display: none"
              /></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
