import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { HashLocationStrategy, LocationStrategy,CommonModule} from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { HeaderComponent } from './components/common/header/header.component';
import { CalendarComponent } from './components/dashboard/calendar/calendar.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SidebarComponent } from './components/common/sidebar/sidebar.component';
import { TeamComponent } from './components/dashboard/team/team.component';
import { NotificationComponent } from './components/dashboard/notification/notification.component';
import { CallsComponent } from './components/dashboard/calls/calls.component';
import { AudioCallComponent } from './components/dashboard/calls/audio-call/audio-call.component';
import { MaterialModule } from './material/material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CallLogComponent } from './components/dashboard/call-log/call-log.component';
import { ChatComponent } from './components/dashboard/chat/chat.component';
import { ContactComponent } from './components/dashboard/contact/contact.component';
import { profileComponent } from './components/dashboard/profile/profile.component';
import { GroupComponent } from './components/dashboard/group/group.component';
import { ContactusComponent } from './components/dashboard/contactus/contactus.component';
import { ContactUsComponent } from './components/contactus/contactus.component';
import { receivingcallComponent } from './components/receivingcall/receivingcall.component';

import { DialingComponent } from './components/dialing/dialing.component';
import { GroupVideoCallComponent } from './components/group-video-call/group-video-call.component';

import * as moment from 'moment';
import { LoadingComponent } from './components/common/loading/loading.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LoaderService } from './services/loader.service';
import { TokenInterceptorService } from './interceptors/token.interceptor';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { AuthGuardService } from './services/auth-guard.service';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { NgxCaptchaModule } from 'ngx-captcha';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { FaqComponent } from './components/faq/faq.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { TermserviceComponent } from './components/termservice/termservice.component';


const config: SocketIoConfig = { url:'https://call.cbcapps.com:4001/',//'https://nodeserver.mydevfactory.com:3011/',
                   options: {autoConnect:false} };

                  //  const config: SocketIoConfig = { url:'http://192.168.0.168:4001/',//'https://nodeserver.mydevfactory.com:3011/',
                  //  options: {autoConnect:false} };

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ResetPasswordComponent,
    FaqComponent,
    RegisterComponent,
    HeaderComponent,
    CalendarComponent,
    DashboardComponent,
    SidebarComponent,
    TeamComponent,
    NotificationComponent,
    CallsComponent,
    AudioCallComponent,
    CallLogComponent,
    ChatComponent,
    DialingComponent,
    receivingcallComponent,
    GroupVideoCallComponent,
    LoadingComponent,
    ContactComponent,
    GroupComponent,
    ContactusComponent,
    ContactUsComponent,
    profileComponent,
    TermserviceComponent
  ],
  imports: [
    CommonModule ,
    BrowserModule,
    SocketIoModule.forRoot(config),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    MaterialModule,
    BrowserAnimationsModule,
    CarouselModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      progressBar: true,
      progressAnimation: 'decreasing',
      preventDuplicates: true,
      maxOpened: 1,
      autoDismiss: true
    }),
    NgxSpinnerModule,
    NgxCaptchaModule,
    PickerModule
  ],
  providers: [
    AuthGuardService,
    LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true },
    {provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
