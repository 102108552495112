import { Component, OnInit } from '@angular/core';
import { FaqService } from 'src/app/services/faq.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  constructor(private _priv: FaqService) { }

  innerData: any = '';


  ngOnInit(): void {

    this._priv.faqData().subscribe((faqAllData) => {  

      // do something with the data here  

      this.innerData = faqAllData.response_data;
      console.log(this.innerData);

      });  

  


      
  
  }

}
